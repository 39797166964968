import { CreatesitecalendarComponent } from "@app/pages/createsitecalendar/createsitecalendar.component";
import { GlobalModule } from "@app/GlobalModule/GlobalModule.module";
import { CreateemployeetypeComponent } from "./pages/createemployeetype/createemployeetype.component";
import { CreateTherapeuticAreaComponent } from "./pages/createtherapeuticarea/create-therapeutic-area.component";
import { CreatetherapeuticindicationComponent } from "./pages/createtherapeuticindication/createtherapeuticindication.component";
import { CreatecampaignrefsourceComponent } from "./pages/createcampaignrefsource/createcampaignrefsource.component";
import { CreatecampaignComponent } from "./pages/createcampaign/createcampaign.component";
// import { CreatecampaignrefsourceComponent } from "./pages/createcampaignrefsource/createcampaignrefsource.component";
// import { CreatecampaignComponent } from "./pages/createcampaign/createcampaign.component";
import { CreatePrincipalInvestigatorComponent } from "./pages/create-principal-investigator/create-principal-investigator.component";
import { CreateuserroleComponent } from "./pages/createuserrole/createuserrole.component";
import { CreatestudyvisitComponent } from "./pages/createstudyvisit/createstudyvisit.component";
import { CreatestaffComponent } from "./pages/createstaff/createstaff.component";
import { CreatestudyComponent } from "./pages/createstudy/createstudy.component";
import { ScreenereditComponent } from "./pages/screeneredit/screeneredit.component";
import { DatepickerModule } from "@app/components/datepicker/datepicker.module";
import { AssignpatientComponent } from "./pages/assignpatient/assignpatient.component";
//import { ImportCRIOStudyVisitsComponent } from '@app/pages/importCrioStudyVisits/importCRIOStudyVisits.component';
import * as Raven from "raven-js";
import {
  NgModule,
  ErrorHandler,
  isDevMode,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { GlobalfunctionService } from "@sharedservices/FrontServices/globalfunction.service";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { DatePipe, DecimalPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { FieldErrorDisplayComponentCmp } from './pages/field-error-display-cmp/field-error-display-cmp.component';
import { CommonModule } from "@angular/common";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { NgxMaskModule } from "ngx-mask";
import { BrowserModule } from "@angular/platform-browser";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { environment } from "../environments/environment";
import { AgmCoreModule } from "@agm/core";
import { NgxSocialButtonModule, SocialServiceConfig } from "ngx-social-button";
import { PickerModule } from '@ctrl/ngx-emoji-mart';


// Configs
export function getAuthServiceConfigs() {
  let config = new SocialServiceConfig()
    // .addFacebook("Your-Facebook-app-id")
    .addGoogle(
      "687199414693-c4ocf6bslimh2mfipbir5o6fiiofgrku.apps.googleusercontent.com"
    );
  //.addLinkedIn("Your-LinkedIn-Client-Id");
  return config;
}
Raven
  // commit kaleem on 06oct2020
  // .config('https://6d23a9d51ff04a3dafd284e6a526ad62@sentry.io/1306826')
  .config(environment.sentry_URL)
  .install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    }
  }
}

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatStepper,
} from "@angular/material";
import {
  NgbPaginationModule,
  NgbAlertModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { FlatpickrModule } from "angularx-flatpickr";
import { AppComponent } from "./app.component";
import { SidebarModule } from "./sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { FixedpluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";
import { FieldErrorDisplayComponentCmp } from "./pages/field-error-display-cmp/field-error-display-cmp.component";
import { FieldErrorDisplayComponent } from "./field-error-display/field-error-display.component";
import { LoginComponent } from "./login/login.component";
import { SweetAlertComponent } from "./components/sweetalert/sweetalert.component";
import { GoogleAnalyticsService } from "@sharedservices/FrontServices/google-analytics.service";
// import { NotificationComponent } from './notification/notification.component';
import { ExcelService } from '@sharedservices/FrontServices/Export/excel.service';
import { EncodeHttpParamsInterceptor } from './EncodeHttpParamsInterceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PwaService } from '@sharedservices/FrontServices/PWA/pwa.service';
import { IconService } from '@sharedservices/BackServices/ComTrak/Icon/icon.service';
import { PromptComponent } from '@app/prompt/prompt.component';
import { CalllistdetailsComponent } from './pages/calllistdetails/calllistdetails.component';
import { RecruitercalllistMarkComponent } from './pages/recruitercalllist-mark/recruitercalllist-mark.component';
import { RecruitercalllistNotesComponent } from './pages/recruitercalllist-notes/recruitercalllist-notes.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { CreatepatientComponent } from './pages/createpatient/createpatient.component';
import { CreatesitesComponent } from './pages/createsites/createsites.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RegisterationComponent } from './Utilties/registeration/registeration.component';
import { AgGridModule } from 'ag-grid-angular';
import { TemplaterenderComponent } from './aggrid/templaterender/templaterender.component';
import { VisitorflowcolumnComponent } from './Utilties/visitorflowcolumn/visitorflowcolumn.component';
import { VisitorflowsitecolumnComponent } from './Utilties/visitorflowsitecolumn/visitorflowsitecolumn.component';
import { ConfirmComponent } from './Utilties/confirm/confirm.component';
import { AssignleadstorecruiterComponent } from './pages/assignleadstorecruiter/assignleadstorecruiter.component';
import { VerifypoolComponent } from './pages/verifypool/verifypool.component';
import { EditleadComponent } from './pages/editlead/editlead.component';
import { DiscardleadComponent } from './pages/discardlead/discardlead.component';
import { MergeleadComponent } from './pages/mergelead/mergelead.component';
import { CreatealertconfigurationComponent } from './Communication/createalertconfiguration/createalertconfiguration.component';
import { CreatealerttemplateComponent } from './Communication/createalerttemplate/createalerttemplate.component';
import { TemplatestepperComponent } from './Communication/templatestepper/templatestepper.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CreateconfigsmsresponseComponent } from './Communication/createconfigsmsresponse/createconfigsmsresponse.component';
// import { CountUpModule } from 'ngx-countup';
import { QuillModule } from 'ngx-quill';
import { DblmstepperComponent } from './DBLM/dblmstepper/dblmstepper.component';
import { CreatelistComponent } from './DBLM/createlist/createlist.component';
import { CreateinclusionComponent } from './DBLM/createinclusion/createinclusion.component';
import { AssignlistComponent } from './DBLM/assignlist/assignlist.component';
import { ReassignlistComponent } from './DBLM/reassignlist/reassignlist.component';
import { ImportdblmComponent } from './DBLM/importdblm/importdblm.component';
import { CreatesiteroomComponent } from './pages/createsiteroom/createsiteroom.component';
import { CreatesitePIComponent } from './pages/createsite-pi/createsite-pi.component';
import { DatatableModule } from './components/datatable/datatable.module';
import { TotalrecordshowModule } from './components/totalrecordshow/totalrecordshow.module';
import { AddciostudyComponent } from "./pages/addciostudy/addciostudy.component";
//import { PatientProfileComponent } from "./pages/pa/createstudyvisits.component";
import { CreatevaccineComponent } from "./pages/createvaccine/createvaccine.component";
import { CreateManufacturerComponent } from "./pages/createmanufacturer/createmanufacturer.component";
import { CreateIndicationComponent } from "./pages/createindication/createindication.component";
import { MergePatientComponent } from './pages/mergepatient/mergepatient.component';
import { ShowCampaignsComponent } from "./pages/showcampaign/showcampaigns.component";

import { RecruiterAssignmentComponent } from "./pages/recruiter-assignment/recruiter-assignment.component";
import { DropDownListAllModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { AlertModalComponent } from './pages/alert-modal/alert-modal.component';
import { DraftdetailsComponent } from "./pages/draftdetails/draftdetails.component";
import { PublisheddetailsComponent } from "./pages/publisheddetails/publisheddetails.component";
import { DeclinependingreviewComponent } from "./pages/declinependingreview/declinependingreview.component";
import { DeleteModalComponent } from "./pages/delete-modal/delete-modal.component";
import { PageNotFoundComponent } from "./Utilties/page-not-found/page-not-found.component";
import { draftdetailsCommunicationComponent } from "./pages/draftdetailsCommunication/draftdetailsCommunication.component";
import { SendTestComponent } from "./pages/sendtest/sendtest.component";
import { MarkInteractionComponent } from "./pages/markinteraction/markinteraction.component";
import { SideNavBarComponent } from "./layouts/side-nav-bar/side-nav-bar.component";
import { PreviewComponent } from "./pages/preview/preview.component";
import { AnalyticsmodalComponent } from "./pages/anyalicsmodal/analyticsmodal.component";
import { DraftdetailsPrescreenComponent } from "./pages/draftdetailsPrescreen/draftdetailsPrescreen.component";
import { PublisheddetailsPrescreenComponent } from "./pages/publisheddetailsPrescreen/publisheddetailsPrescreen.component";
import { PreviewPrescreenBuilderFormComponent } from "./pages/previewprescreenbuilderform/previewprescreenbuilderform.component";
import { DateTimePickerModule, TimePickerModule } from "@syncfusion/ej2-angular-calendars";
import { PreviewPdfComponent } from "./pages/previewpdf/previewpdf.component";
import { AddscriptComponent } from "./pages/addscript/addscript.component";
import { AssigncommunicationComponent } from "./pages/assign-communication/assign-communication.component";
import { AcknowledgeComponent } from "./pages/acknowledge/acknowledge.component";
import { CreatememoComponent } from "./pages/creatememo/creatememo.component";
import { ViewMemoComponent } from "./pages/view-memo/view-memo.component";
import { ChatModalComponent } from "./layouts/chat-modal/chat-modal.component";
import { NotificationToasterComponent } from "./layouts/notification-toaster/notification-toaster.component";
import { IncomingCallComponent } from "./pages/incomingcall/incomingcall.component";
import { PreviewNotificationComponent } from "./pages/previewnotification/previewnotification.component";
import { SliderModule } from '@syncfusion/ej2-angular-inputs';
import { CreateAutomatedNotificationComponent } from "./pages/createautomatednotification/createautomatednotification.component";
import { CloseRecruiterAssignmentPromptComponent } from "./pages/close-recruiter-assignment-prompt/close-recruiter-assignment-prompt.component";
import { draftdetailsPatientPoolComponent } from "./pages/draftdetailsPatientPool/draftdetailsPatientPool.component";
import { TransferDistoComponent } from "./pages/transferdisto/transferdisto.component";
import { EmailConnectDialogComponent } from "./pages/email-connect/email-connect.component";
import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';
import { StatisticsModule } from "./components/statistics/statistics.module";
import { IvrOptionComponent } from "./pages/ivr-option/ivr-option.component";
import { IvrScriptComponent } from "./pages/ivr-script/ivr-script.component";
import { SwitchModule } from "@syncfusion/ej2-angular-buttons";
import { ShowPoolStatusesInfoComponent } from "./pages/show-pool-statuses-info/show-pool-statuses-info.component";
import { refreshPoolDetailsComponent } from "./pages/refreshPoolDetails/refreshPoolDetails.component";
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { SlotAdditionComponent } from "./recruiter/slot-addition/slotaddition.component";
import { DateRangepickerModule } from "./components/daterangepicker/daterangepicker.module";
import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { SlotAuditComponent } from "./recruiter/slot-audit/slotaudit.component";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import { ContentPreviewComponent } from "./components/Preview/ContentPreview.component";
import { PoolPatientListComponent } from "./pages/pool-patient-list/pool-patient-list.component";
import { DropPatientsConfirmationComponent } from "./pages/pool-patient-list/drop-patients-confirmation/drop-patients-confirmation.component";
import { HolidayForAllComponent } from "./pages/holiday-for-all/holiday-for-all.component";
import { HolidayPreviewComponent } from "./pages/holiday-preview/holiday-preview.component";
import { HolidayForWeekComponent } from "./pages/holiday-for-week/holiday-for-week.component";
import { DraftdetailsPatientPoolCriteriaComponent } from "./pages/draftdetailsPatientPool/draftdetails-patient-pool-criteria/draftdetails-patient-pool-criteria.component";
import { DraftdetailsPatientPoolDateComponent } from "./pages/draftdetailsPatientPool/draftdetails-patient-pool-date/draftdetails-patient-pool-date.component";

const initializer = (pwaService: PwaService) => () =>
  pwaService.initPwaPrompt();

@NgModule({
  exports: [
    PickerModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatBadgeModule,
    // NotificationComponent,
    NgxChartsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatBottomSheetModule,
    MatStepperModule,
    NgbPaginationModule,
    NgbAlertModule,
    SwitchModule,
    TimePickerModule

  ],
  // declarations: [NotificationComponent]
})
export class MaterialModule { }

@NgModule({
  imports: [
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsightsInstrumentationKey
    }),
    PickerModule,
    //DatatableModule,
    // TotalrecordshowModule,
    AgGridModule.withComponents([TemplaterenderComponent]),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPowerBiModule,
    NgxSocialButtonModule,
    CommonModule,
   

    BrowserAnimationsModule,
    FormsModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD48R3gqfXJX-ohhHueOHdt7ZuPMR_b_ow",
      libraries: ["drawing", "visualization"],
    }),
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: "enabled", // or 'top'
      anchorScrolling: "enabled",
    }),
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: "toast-bottom-left",
      progressBar: true,
      // countDuplicates: true,
    }),
    HttpModule,
    MaterialModule,
    MatNativeDateModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    BrowserModule,
    NgxSpinnerModule,
    NgxChartsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DatepickerModule,
    NgbModule,
    DragDropModule,
    GlobalModule,
    AngularEditorModule,
    DropDownListAllModule,
    MultiSelectModule,
    MatListModule,
    RichTextEditorAllModule,
    DateTimePickerModule,
    DateRangepickerModule,
    TimePickerModule,
    DropDownListModule,
    SliderModule,
    // CountUpModule,
    QuillModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true }),
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsightsInstrumentationKey
    }),
    StatisticsModule,
    NgxTrimDirectiveModule
  ],
  declarations: [
    // TemplaterenderComponent,
    // AlphabetOnlyDirective,
    AppComponent,
    LoginComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    CalllistdetailsComponent,
    RecruitercalllistMarkComponent,
    RecruitercalllistNotesComponent,
    SweetAlertComponent,
    FieldErrorDisplayComponent,
    FieldErrorDisplayComponentCmp,
    PromptComponent,
    CreatepatientComponent,
    AssignpatientComponent,
    ScreenereditComponent,
    CreatesitesComponent,
    CreatestudyComponent,
    CreatestaffComponent,
    CreatestudyvisitComponent,
    //CreatestudyvisitsComponent,
    CreateuserroleComponent,
    CreatecampaignComponent,
    CreatePrincipalInvestigatorComponent,
    CreatecampaignrefsourceComponent,
    CreateemployeetypeComponent,
    CreateTherapeuticAreaComponent,
    CreatetherapeuticindicationComponent,
    CreatesitecalendarComponent,
    RegisterationComponent,
    VisitorflowsitecolumnComponent,
    VisitorflowcolumnComponent,
    ConfirmComponent,
    AssignleadstorecruiterComponent,
    VerifypoolComponent,
    EditleadComponent,
    DiscardleadComponent,
    MergeleadComponent,
    CreatealerttemplateComponent,
    CreatealertconfigurationComponent,
    TemplatestepperComponent,
    CreateconfigsmsresponseComponent,
    AlertModalComponent,
    DblmstepperComponent, CreatelistComponent, CreateinclusionComponent, AssignlistComponent, ReassignlistComponent, ImportdblmComponent,
    CreatesiteroomComponent, CreatesitePIComponent, AddciostudyComponent,
    //ImportCRIOStudyVisitsComponent,
    CreatevaccineComponent,
    CreateManufacturerComponent,
    CreateIndicationComponent,
    MergePatientComponent,
    ShowCampaignsComponent,
    RecruiterAssignmentComponent,
    CloseRecruiterAssignmentPromptComponent,
    DraftdetailsComponent,
    PublisheddetailsComponent,
    DeclinependingreviewComponent,
    SlotAdditionComponent,
    SlotAuditComponent,
    DeleteModalComponent,
    PageNotFoundComponent,
    draftdetailsCommunicationComponent,
    draftdetailsPatientPoolComponent,
    SendTestComponent,
    MarkInteractionComponent,
    SideNavBarComponent,
    NotificationToasterComponent,
    ChatModalComponent,
    PreviewComponent,
    AnalyticsmodalComponent,
    DraftdetailsPrescreenComponent,
    PublisheddetailsPrescreenComponent,
    PreviewPrescreenBuilderFormComponent,
    PreviewPdfComponent,
    AddscriptComponent,
    AssigncommunicationComponent,
    AcknowledgeComponent,
    IncomingCallComponent,
    CreatememoComponent,
    ViewMemoComponent,
    PreviewNotificationComponent,
    CreateAutomatedNotificationComponent,
    TransferDistoComponent,
    EmailConnectDialogComponent,
    IvrOptionComponent,
    IvrScriptComponent,
    refreshPoolDetailsComponent,
    ShowPoolStatusesInfoComponent,
    ContentPreviewComponent,
    refreshPoolDetailsComponent,
    PoolPatientListComponent,
    DropPatientsConfirmationComponent,
    HolidayForAllComponent,
    HolidayPreviewComponent,
    HolidayForWeekComponent,
    DraftdetailsPatientPoolCriteriaComponent,
    DraftdetailsPatientPoolDateComponent,

  ],
  providers: [
    // {
    // provide: HTTP_INTERCEPTORS,
    // useClass: MyInterceptor,
    // multi: true
    // },
    {
      provide: SocialServiceConfig,
      useFactory: getAuthServiceConfigs,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true,
    },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: ErrorHandler, useClass: RavenErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [PwaService],
      multi: true,
    },
    AppInsightsService,
    DecimalPipe,
    GlobalfunctionService,
    // SentryErrorHandler,
    DatePipe,
    SweetAlertComponent,
    ExcelService,
    GoogleAnalyticsService,
    IconService,
    AppInsightsService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CalllistdetailsComponent,
    RecruitercalllistMarkComponent,
    RecruitercalllistNotesComponent,
    PromptComponent,
    CreatepatientComponent,
    AssignpatientComponent,
    ScreenereditComponent,
    CreatesitesComponent,
    CreatestudyComponent,
    CreatestaffComponent,
    CreatestudyvisitComponent,
    CreateuserroleComponent,
    CreatecampaignComponent,
    CreatePrincipalInvestigatorComponent,
    CreatecampaignrefsourceComponent,
    CreateemployeetypeComponent,
    CreateTherapeuticAreaComponent,
    CreatetherapeuticindicationComponent,
    CreatesitecalendarComponent,
    VisitorflowsitecolumnComponent,
    VisitorflowcolumnComponent,
    ConfirmComponent,
    AssignleadstorecruiterComponent,
    VerifypoolComponent,
    EditleadComponent,
    DiscardleadComponent,
    MergeleadComponent,
    CreatealerttemplateComponent,
    CreatealertconfigurationComponent,
    TemplatestepperComponent,
    CreateconfigsmsresponseComponent,
    AlertModalComponent,
    DblmstepperComponent,
    CreatelistComponent,
    CreateinclusionComponent,
    AssignlistComponent, ReassignlistComponent, ImportdblmComponent,
    CreatesiteroomComponent, CreatesitePIComponent, AddciostudyComponent,
   // ImportCRIOStudyVisitsComponent,
   // CreatestudyvisitsComponent
   CreatevaccineComponent,
   CreateManufacturerComponent,
   CreateIndicationComponent,
   MergePatientComponent,
   ShowCampaignsComponent,
   RecruiterAssignmentComponent,
   CloseRecruiterAssignmentPromptComponent,
   DraftdetailsComponent,
   PublisheddetailsComponent,
   DeclinependingreviewComponent,
   SlotAdditionComponent,
   SlotAuditComponent,
   DeleteModalComponent,
   draftdetailsCommunicationComponent,
   draftdetailsPatientPoolComponent,
   SendTestComponent,
   MarkInteractionComponent,
   PreviewComponent,
   AnalyticsmodalComponent,
   DraftdetailsPrescreenComponent,
   PublisheddetailsPrescreenComponent,
   PreviewPrescreenBuilderFormComponent,
   PreviewPdfComponent,
   AddscriptComponent,
   AssigncommunicationComponent,
   AcknowledgeComponent,
   CreatememoComponent,
   ViewMemoComponent,
   IncomingCallComponent,
   PreviewNotificationComponent,
   CreateAutomatedNotificationComponent,
   TransferDistoComponent,
   EmailConnectDialogComponent,
   IvrOptionComponent,
   IvrScriptComponent,
   refreshPoolDetailsComponent,
   ShowPoolStatusesInfoComponent,
   ContentPreviewComponent,
   refreshPoolDetailsComponent,
   PoolPatientListComponent,
   DropPatientsConfirmationComponent,
   HolidayForAllComponent,
   HolidayPreviewComponent,
   HolidayForWeekComponent,
   DraftdetailsPatientPoolCriteriaComponent,
   DraftdetailsPatientPoolDateComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // exports:[AlphabetOnlyDirective]
})
export class AppModule { }
