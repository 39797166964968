import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { CallList } from '@sharedmodels/BackModels/ComTrak/CallLists/CallList';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenerService {

  public _callList: CallList[];
  public _VisitedCallList: CallList[];
  public _callListArray: CallList[];
  public arraylength: number;
  public isCallApi = new Subject<boolean>();
  public isCallApiDNQList = new Subject<boolean>();
  public _DNQListArray: any[] = [];
  public StudyId: number = null
  public SiteId: number = null
  public StatusId: number = null
  public StartDate: string = null
  public EndDate: string = null
  public SearchString: string = ''

  constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService,
    private spinner: NgxSpinnerService) { }

  LoadScreenerCallList(ediQuestion: boolean, studyid: string, siteid: string, statusid: string, studyvisitid: string, startDate: any, endDate: any,
    pageSize: number, pageNo: number, searchText: string, responseId: string, pathParam: string = null): any {
    try {
      this.spinner.show();
      const pagesize = pageSize;
      const pageno = pageNo;
      this._Global.IsReady = false;
      studyvisitid = studyvisitid == '' ? studyvisitid = '-1' : studyvisitid;
      if (pathParam) {
        const reqObj = {
          pageno,
          pagesize,
          editQuestions: ediQuestion,
          getType: 'GetScreenerCallList',
          subStatusId: studyvisitid,
          responseId: responseId
        }

        if (!searchText) {
          if (studyid && studyid.length) {
            reqObj['studyIds'] = studyid;
          }
  
          if (siteid) {
            reqObj['siteIds'] = siteid;
          }
  
          if (statusid && statusid.length) {
            reqObj['statusIds'] = statusid;
          }
          if (startDate) {
            reqObj['startDate'] = startDate;
          }
  
          if (endDate) {
            reqObj['endDate'] = endDate;
          }
        } else {
          reqObj['searchString'] = searchText;
        }
        return this._http.post(myGlobals._apiPath + `SceenersCallList${pathParam && ('/' + pathParam)}`, reqObj, { headers: this._Global.httpOptions });
      } else {
        studyid = studyid == '' ? studyid = '-1' : studyid;
        siteid = siteid == '' ? siteid = '-1' : siteid;
        statusid = statusid == '' ? statusid = '-1' : statusid;
        return this._http.get(myGlobals._apiPath + 'SceenersCallList?getType=GetScreenerCallList&editQuestions=' + ediQuestion + '&studyId=' + studyid + '&siteId=' + siteid
          + '&statusid=' + statusid + '&subStatusId=' + studyvisitid + '&startDate=' + startDate + '&endDate=' + endDate
          + '&pageno=' + pageno + '&pagesize=' + pagesize + '&searchString=' + searchText + '&responseId=' + responseId
          , { headers: this._Global.httpOptions });
      }
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }

  GetScreenerCallList(ediQuestion: boolean, studyid: string, siteid: string, statusid: string, studyvisitid: string, startDate: any, endDate: any,
    pageSize: number, pageNo: number, searchText: string, responseId: string, pathParam: string = null) {
    this._Global.IsReady = false
    this.spinner.show()
    this.LoadScreenerCallList(ediQuestion, studyid, siteid, statusid, studyvisitid, startDate, endDate, pageSize, pageNo, searchText, responseId, pathParam).subscribe(data => {
      this._callList = <CallList[]>data;
      this._callListArray = <CallList[]>data;
      
      this.arraylength = this._callList.length
      this._Global.IsReady = true;
      this.spinner.hide();
      this.isCallApi.next(true)
    },
      error => {
        this.isCallApi.next(false)
        this._Global.handleError(error);
        this.spinner.hide();
      });
  }

  DeleteInteraction(patientInteractionId: number) {
    return this._http.delete(myGlobals._apiPath + 'SceenersCallList?patientInteractionId=' + patientInteractionId, { headers: this._Global.httpOptions });
  }

  GetDNQList(studyId: number, siteId: number, statusId: number, startDate: string, endDate: string, searchString: string): any {
    try {
      this.spinner.show();
      this._Global.IsReady = false;
      this._http.get(myGlobals._apiPath + 'SceenersCallList?getType=GetDNQList&StudyId=' + studyId + '&SiteId=' + siteId + '&StatusId=' + statusId
        + '&startDate=' + startDate + '&endDate=' + endDate + '&searchString=' + searchString, { headers: this._Global.httpOptions }).subscribe(res => {
          this._DNQListArray = <any[]>res;
          this._Global.IsReady = true;
          this.spinner.hide();
        }, error => {
          this.isCallApi.next(false)
          this._Global.handleError(error);
          this.spinner.hide();
        })
    } catch (x) {
      this._Global.showErrorMsg(x);
      this._Global.IsReady = true;
      this.spinner.hide();
    }
  }
}



