import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '@shared/global_var';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { GoogleAnalyticsService } from '@sharedservices/FrontServices/google-analytics.service';
import { CallList } from '@sharedmodels/BackModels/ComTrak/CallLists/CallList';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserRoleService {
    public rolesRoute: any;
    public rolesRouteCopy: any;
    public rolesRouteOriginal: any;
    public roleUpdated: boolean = false;
    public isCallApi = new Subject<boolean>();
    public isCallApiSave = new Subject<boolean>();
    public isCallApiDelete = new Subject<boolean>();
    public _roles: any[];
    public _roleForReassign: any[];
    public _userRoles: any[];
    public _userRolesArray: any[];
    public _staffUsers: any[];
    public arraylength: number;
    constructor(public _http: HttpClient, private _Global: GlobalfunctionService, private _GA: GoogleAnalyticsService,
        private spinner: NgxSpinnerService) { }

    LoadRoles() {
        try {
            this.spinner.show();
            this._http.get(myGlobals._apiPath + 'UserRole', { headers: this._Global.httpOptions }).subscribe(data => {
                this._roles = <any[]>data;
                this._Global.IsReady = true;
                this.spinner.hide();
            },
                error => {
                    //  console.log(JSON.stringify(error));
                    this._Global.handleError(error);
                    this.spinner.hide();
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide();
        }
    }

    GetRoles() {
        if (this._roles !== undefined && this._roles.length !== null) {
            return this._roles;
        } else {
            this.LoadRoles();
        }
    }

    LoadAllUserRoleRoles(searchString: string) {
        try {
            this._Global.IsReady = false;
            this.spinner.show();
            // this.isCallApi.next(false)
            this._http.get(myGlobals._apiPath + 'UserRole?type=GetAllUserRole&searchString=' + searchString, { headers: this._Global.httpOptions }).subscribe(data => {
                this._userRoles = <any[]>data;
                this._userRolesArray = <any[]>data;
                this.arraylength = this._userRoles.length
                this._Global.IsReady = true;
                this.spinner.hide();
                // this.isCallApi.next(true)
            },
                error => {
                    //console.log(JSON.stringify(error));
                    this._Global.handleError(error);
                    this.spinner.hide();
                    // this.isCallApi.next(false)
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide();
            // this.isCallApi.next(false)
        }
    }

    GetAllUserRoleRoles(searchString: string) {
        if (this._userRoles !== undefined && this._userRoles.length !== null) {
            return this._userRoles;
        } else {
            this.LoadAllUserRoleRoles(searchString);
        }
    }

    LoadstaffUsers() {
        try {
            this._Global.IsReady = false;
            this.spinner.show();
            this._http.get(myGlobals._apiPath + 'UserRole?type=GetstaffUsers', { headers: this._Global.httpOptions }).subscribe(data => {
                this._staffUsers = <any[]>data;
                this._Global.IsReady = true;
                this.spinner.hide();
            },
                error => {
                    //        console.log(JSON.stringify(error));
                    this._Global.handleError(error);
                    this.spinner.hide();
                    this._Global.IsReady = true;
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide();
        }
    }

    GetstaffUsersRoles() {
        if (this._staffUsers !== undefined && this._staffUsers.length !== null) {
            return this._staffUsers;
        } else {
            this.LoadstaffUsers();
        }
    }

    SaveUserRole(body: any) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.post(myGlobals._apiPath + 'UserRole', body, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("User Role Saved Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    // this.LoadAllUserRoleRoles('')
                },
                    error => {
                        this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        this.isCallApi.next(false)
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    EditUserRole(body: any) {
        try {
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'UserRole?roleName=' + body.RoleName + "&email=" + body.Email, body, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this.isCallApi.next(true)
                    this._Global.showSuccessMsg("User Role Updated Successfully")
                    this.spinner.hide()
                    this._Global.IsReady = true;
                    // this.LoadAllUserRoleRoles('')
                },
                    error => {
                        if (error.status == 200) {
                            this.isCallApi.next(true)
                            this._Global.showSuccessMsg("User Role Updated Successfully")
                            this.spinner.hide()
                            this._Global.IsReady = true;
                            // this.LoadAllUserRoleRoles('')
                        }
                        else {
                            this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                            this.spinner.hide()
                            this._Global.IsReady = true;
                            this.isCallApi.next(false)
                        }
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }

    SaveAndEditUserRole(body: any, isEdit: boolean) {
        if (isEdit) {
            this.EditUserRole(body)
        }
        else {
            this.SaveUserRole(body)
        }
    }

    DeleteUserRole(useremail: string) {
        try {
            if (confirm("Are you sure you want to delete all roles against this user?") == true) {
                this.spinner.show()
                this._Global.IsReady = false;
                this._http.delete(myGlobals._apiPath + 'UserRole?email=' + useremail, { headers: this._Global.httpOptions })
                    .subscribe(data => {
                        this.isCallApiDelete.next(true)
                        this._Global.showSuccessMsg("User Role Deleted Successfully")
                        this.spinner.hide()
                        this._Global.IsReady = true;
                        // this.LoadAllUserRoleRoles('')
                    },
                        error => {
                            if (error.status == 200) {
                                this.isCallApiDelete.next(true)
                                this._Global.showSuccessMsg("User Role Deleted Successfully")
                                this.spinner.hide()
                                this._Global.IsReady = true;
                                // this.LoadAllUserRoleRoles('')
                            }
                            else {
                                this._Global.handleError(error);
                                this.spinner.hide()
                                this._Global.IsReady = true;
                                this.isCallApiDelete.next(false)
                            }

                        });
            }
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }


    LoadRolesRoutes() {
        try {
            this.rolesRoute = null;
            this.rolesRouteCopy = null;
            this.rolesRouteCopy = null;
            console.log("Calling Load ROles Api")
            this.spinner.show();
            this._http.get(myGlobals._apiPath + 'routes?getType=setup', { headers: this._Global.httpOptions }).subscribe(data => {
                console.log("Inside Load Roles Api")
                this.rolesRoute = data;
                this.rolesRouteCopy = JSON.parse(JSON.stringify(this.rolesRoute))
                this.rolesRouteOriginal = JSON.parse(JSON.stringify(this.rolesRoute))
                console.log(data)
                this._Global.IsReady = true;
                // this.spinner.hide();
            },
                error => {
                    //  console.log(JSON.stringify(error));
                    this._Global.handleError(error);
                    this.spinner.hide();
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide();
        }
    }

    GetAllRolesRoute() {
        if (this.rolesRoute !== undefined && this.rolesRoute.length !== null) {
            return this.rolesRoute;
        } else {
            this.LoadRolesRoutes()
        }
    }

    LoadAvailableRoles() {
        try {
            this.spinner.show();
            this._http.get(myGlobals._apiPath + 'Role', { headers: this._Global.httpOptions }).subscribe(data => {
                this._roleForReassign = <any[]>data;
                this._Global.IsReady = true;
                this.spinner.hide();
            },
                error => {
                    //  console.log(JSON.stringify(error));
                    this._Global.handleError(error);
                    // this.spinner.hide();
                });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide();
        }
    }

    GetAllAvailableRoles() {
        if (this._roleForReassign !== undefined && this._roleForReassign.length !== null) {
            return this._roleForReassign;
        } else {
            this.LoadAvailableRoles()
        }
    }

    reassignRoles(rolesData) {
        try {
            this.rolesRoute = null;
            this.rolesRouteCopy = null;
            this.rolesRouteCopy = null;
            this.spinner.show()
            this._Global.IsReady = false;
            this._http.put(myGlobals._apiPath + 'routes?getType=setup', rolesData, { headers: this._Global.httpOptions })
                .subscribe(data => {
                    this._Global.showSuccessMsg("User Role Updated Successfully")
                    this._Global.IsReady = true
                },
                    error => {
                        if (error.status == 200) {
                            this.roleUpdated = true;
                            console.log("Inside Update API")
                            this.isCallApi.next(true)
                            this._Global.showSuccessMsg("User Role Updated Successfully")
                            this.spinner.hide()
                            this._Global.IsReady = true;
                            // this.LoadAllUserRoleRoles('')
                        }
                        else {
                            this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
                            this.spinner.hide()
                            this._Global.IsReady = true;
                            // this.isCallApi.next(false)
                        }
                    });
        } catch (x) {
            this._Global.showErrorMsg(x);
            this._Global.IsReady = true;
            this.spinner.hide()
            this.isCallApi.next(false)
        }
    }
}
