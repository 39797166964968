import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StudyQuestionService } from './../../../Shared/Services/Question/studyquestion.service';
import { StudyvisitService } from '@sharedservices/BackServices/ComTrak/Setups/studyvisit.service';
import { CallListType, LovStatusMasterID } from './../../../Shared/Models/DMCREnums/DMCREnums';
import { PatientInteractionService } from './../../../Shared/Services/BackServices/ComTrak/Setups/patientInteraction.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { PatientInteraction } from '@sharedmodels/BackModels/ComTrak/Interactions/PatientInteraction';
import { SiteCalendarService } from './../../../Shared/Services/BackServices/ComTrak/Setups/sitecalendar.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Injector, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChangeDetectionStrategy, } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDateFormatter, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { StudyService } from '@sharedservices/BackServices/ComTrak/Setups/study.service';
import { SiteService } from '@sharedservices/BackServices/ComTrak/Setups/site.service';
import { StatusService } from '@sharedservices/BackServices/ComTrak/Setups/status.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { StudyQuestion } from '@sharedmodels/BackModels/ComTrak/Setups/StudyQuestion';
import * as moment from 'moment';
import { Study } from '@sharedmodels/BackModels/ComTrak/Setups/Study';
import { Site } from '@sharedmodels/BackModels/ComTrak/Setups/Site';
import * as myGlobals from '@shared/global_var';
import { PrescreenerService } from '@sharedservices/BackServices/ComTrak/Prescreeners/prescreener.service';
import { RecruiterService } from '@sharedservices/BackServices/ComTrak/Recruiters/recruiter.service';
import { ScreenerService } from '@sharedservices/BackServices/ComTrak/Screeners/screener.service';
import { PatientService } from '@sharedservices/BackServices/ComTrak/Setups/patient.service';
import { LovService } from '@sharedservices/BackServices/ComTrak/Setups/lov.service';
import { Lov } from '@sharedmodels/BackModels/ComTrak/Setups/lov';
import { LocationService } from '@sharedservices/BackServices/ComTrak/Setups/location.service';
import { SubStatuses } from '@sharedmodels/BackModels/ComTrak/Setups/SubStatuses';
import { HttpParams } from '@angular/common/http';
import { GlobalfunctionService } from '@sharedservices/FrontServices/globalfunction.service';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { PatientDetailDialogService } from '@app/calllists/patientdetail/patientdetaildialogservice';
import { DATE_FORMAT } from '@shared/Constants';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { PrescreenService } from '@sharedservices/BackServices/ComTrak/Communication/prescreen.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { isArray } from 'jquery';
import { ScriptService } from '@sharedservices/BackServices/ComTrak/Script/script.service';
import { RecruiterService as CommunicationRecruiterService  } from '@sharedservices/BackServices/ComTrak/Communication/recruiter.service';

const colors: any = {
  red: {
    primary: '#00a2ff',
    secondary: '#f8a06e',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-recruitercalllist-mark',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './recruitercalllist-mark.component.html',
  styleUrls: ['./recruitercalllist-mark.component.css', '../../style.component.scss'],
  styles: [
    `
      ::ng-deep .my-custom-class span {
        font-weight: 600 !important;
        color: white !important;
        font-size: 20px !important;
        height: 100% !important;
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      },
    `
  ],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(1500)),

    ]),
  ],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class RecruitercalllistMarkComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('calendar') calendar: any;
  viewDate: any = new Date();
  refreshCalendar: Subject<void> = new Subject();
  format: string = 'M/d/yyyy'
  MarkInteractionForm!: FormGroup
  showCalendar: boolean = false;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  QuestionArray = []
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  selectedMonth = this.viewDate.getMonth();
  selectedYear = this.viewDate.getFullYear();
  selectedEvent: any = [];
  selectedDate: any = this.datePipe.transform(new Date(), 'EEEE, d MMMM, yyyy');;
  public datePickerOptions: FlatpickrDefaultsInterface = {
    allowInput: true,
    enableTime: true,
    mode: 'single',
    dateFormat: "Y-m-d H:i",
    // this:
    // enable: [{ from: new Date(0, 1), to: new Date(new Date().getFullYear() + 200, 12) }]
  }
  public timePickerOptions: FlatpickrDefaultsInterface = {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time24hr: true,
  };
  toastOptions: Partial<IndividualConfig> = {
    timeOut: 3000, // Duration in milliseconds
    positionClass: 'toast-top-right'
  };
  activeDayIsOpen: boolean = false;
  submitted: boolean;
  index: number;
  screenType: string;
  showScreenerFields: boolean;
  StudyQuestion: StudyQuestion[] = [];
  showFields: boolean;
  disabledFields: boolean = false;
  disabledStudyCrioId: boolean = false;
  showMarkScheduleNextVisit: boolean;
  sceenApiType: string;
  dateTime: string;
  disabledButton: boolean = false;
  IsDNQ: boolean = false;
  showCreateButton: boolean = true;
  showNotesMendatory: boolean = true;
  errorMessage: string = "";
  lovData: Lov[] = [];
  marKinteractionNextVisit: boolean = false;
  showScreenerMandatoryFields: boolean = false;
  showSubStatus: boolean = false;
  SubStatuses: SubStatuses[] = [];
  lastInteractionStatus: string;
  lastInteractionStatusId: number;
  filterStatusObj: any;
  ShowLvmCounter: boolean = false;
  incrementCounter: boolean = false;
  showNextDate: boolean = false;
  AllStatusObj: any;
  ConfirmationStatuses: any;
  showConfirmation: boolean = false;
  counterValue: number = 0;
  showEventSchedule: boolean = false;
  showEventContainer: boolean = false;
  selectedTime: string;
  subStatusRequired: boolean = false;
  visitStatuslist: SubStatuses[] = [];
  showUpdatedVisitList: boolean = false;
  statusIDs:any[] = [];
  prescreenFormData:any = [];
  getQuestionValue: FormControl;
  questionList:any[] = [];
  formDetails:any;
  formId:any;
  validity: any;
  study: any = {};
  siteslist: Site[];
  isHideForm = false;
  subs: Subscription;
  showDNQMess = false;
  showQualifiedMess = false;
  qualifiedPatientMess = null;
  DNQPatientMess = null;
  patientDetails: any;
  
  // calendar
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  startDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  calStartDate: any;
  calEndDate: any;
  slotDate = null;
  slotTime = null;

  // available slots
  availableSlotData = [];
  moreSlotsLoader = false;
  slotListPageIndex = 0;
  isMoreData = false;
  updateSlot = false;
  updatedSlotId: number;

    constructor(private modal: NgbModal,  @Optional() public dialogRef: MatDialogRef<RecruitercalllistMarkComponent>,
    private recruiterService: CommunicationRecruiterService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public studyService: StudyService, public statusService: StatusService, public siteService: SiteService, private _Global: GlobalfunctionService,
    public siteCalendarService: SiteCalendarService, public fb: FormBuilder, public spinner: NgxSpinnerService,
    private toastService: ToastrService,
    private prescreenList: PrescreenService,
    public patientInteractionService: PatientInteractionService, public studyvisitService: StudyvisitService, private scriptService: ScriptService,
    public studyQuestionService: StudyQuestionService, public _PrescreenerService: PrescreenerService, public _RecruiterService: RecruiterService,
    public prescreenService: PrescreenService, 
    public _ScreenerService: ScreenerService, public patientService: PatientService, public lovService: LovService, public locationService: LocationService,
    // public _ScreenerService: ScreenerService, public patientService: PatientService, public locationService: LocationService
    private sanitizer: DomSanitizer, private datePipe: DatePipe, private _PatientDetailDialogService: PatientDetailDialogService,
    private cd: ChangeDetectorRef
  ) {
    this.getQuestionValue = new FormControl(null);
    this.subs = new Subscription();
  }

  show: boolean = false;
  @ViewChild('modalContent', {}) modalContent: TemplateRef<any>;
  @Input() markInteractionData = null;
  @Input() rowData = null;
  @Input() expand = false;
  commonFunctionFlag = true;
  public _data: PatientInteraction;
  public studiesonPatientLocation: Study[];
  public sitesonPatientLocation: Site[];
  public edit: boolean = false;
  screenHeightFlag: boolean;
  screenHeight = 0;
  sidenavHeight = 0;
  @ViewChild("interactionheader", { read: ElementRef }) interactionheader: ElementRef;
  @ViewChild("hrtag", { read: ElementRef }) hrtag: ElementRef;
  @ViewChild("interactionform", { read: ElementRef }) interactionform: ElementRef;
  @HostListener('window:resize', ['$event'])
  getScreenHeight() {
    this.getResizedScreenHeight();
  }
  
  ngOnChanges(): void {
    (this.markInteractionData && this.commonFunctionFlag) ? this.commonFunction() : null;
  }

  async ngOnInit() {
    const sidenavHeightSub = this._RecruiterService.sidenavHeight.subscribe((data) => {
      this.sidenavHeight = data;
      this.getResizedScreenHeight();
    });
    this.subs.add(sidenavHeightSub);

    this.getBusinessHrs()
    await this.studyService.GetActiveStudies();
    this.studiesonPatientLocation = this.studyService._activeStudies;
    this.dialogRef ? this.commonFunction() : null;
  }

  ngAfterViewInit(): void {
    this.screenHeightFlag = (this.sidenavHeight - 85) <= (this.interactionheader.nativeElement.offsetHeight + this.hrtag.nativeElement.offsetHeight + (this.interactionform.nativeElement.offsetHeight));
    this.screenHeight = (this.sidenavHeight - 80) - (this.interactionheader.nativeElement.offsetHeight + this.hrtag.nativeElement.offsetHeight);
  }

  getResizedScreenHeight(): void {
    this.screenHeightFlag = (this.sidenavHeight - 85) <= (this.interactionheader.nativeElement.offsetHeight + this.hrtag.nativeElement.offsetHeight + (this.interactionform.nativeElement.offsetHeight));
    this.screenHeight = (this.sidenavHeight - 80) - (this.interactionheader.nativeElement.offsetHeight + this.hrtag.nativeElement.offsetHeight);
  }

  getPatientDetails(id: number): void {

    const payload = {
      patientId: id,
      fromCache: true,
    }

    let getList = this.patientService.getPatinetDataById(payload).subscribe({
      next: (res) => {
        if (res.status) {
          this.patientDetails = res.data;
        } else {
          this.toastService.error('error', '', res.message);
        }
      },
      error: (err: any) => {
        this.toastService.error('error', '', err.message);
      }
    });

    this.subs.add(getList);
  }

  async commonFunction() {
    this.commonFunctionFlag = false;
    this.markInteractionData ? (this.data = this.markInteractionData) : null;
    if (this.data) {
      this.rowData = this.data.rowData || this.rowData;
      this.getPatientDetails(this.rowData.Id);
    }
    if (this.data && this.data.callList !== null) {
      this._data = this.data.callList;
      this.screenType = this.data.screenType;
      this.IsDNQ = this.data.IsDNQ;
      if (this.screenType == CallListType.Screener) {
        this.sceenApiType = "ScreenerCallList";
      }
      else if (this.screenType == CallListType.PreScreener) {
        this.sceenApiType = "PreScreenerCallList";
      }
      else if (this.screenType == CallListType.Recruiter) {
        this.sceenApiType = "RecruiterCallList";
      }
      if (this.screenType == "prescreener" || this.screenType == "screener") {
        this.showFields = true
      }
      else {
        this.showFields = false
      }
      console.log("this.data.callList ======>>>>>>>", this.data.callList);
      console.log("this.rowData  =========>>>>>>>>", this.rowData);
    }

    this.statusService.LoadComplainceStatus();
    this.initForm();
    this.AllStatusObj = this.statusService.GetStatus()
    this.ConfirmationStatuses = this.AllStatusObj.filter(statusObject => {
      return statusObject.Roles.some(role => role.IsConfirmation === true);
    });
    this.lastInteractionStatus = this.data.lastInterationStatus ? this.data.lastInterationStatus : "";
    this.lastInteractionStatusId = this.data.lastInterationStatusId ? this.data.lastInterationStatusId : null;
    let actionListLastInteraction;
    let rolesListLastInteraction;
    if (this.lastInteractionStatusId != null && this.lastInteractionStatusId != 0 && this.lastInteractionStatus != null && this.lastInteractionStatus.trim() !== '') {
      this.filterStatusObj = this.filteredData(this.AllStatusObj, this.data.lastInterationStatusId)
      this.SubStatuses = this.filterStatusObj ? (this.filterStatusObj[0].SubStatuses ? this.filterStatusObj[0].SubStatuses : null) : null
      this.SubStatuses = this.SubStatuses.sort();
      this.counterValue = this._data.Counter
      actionListLastInteraction = this.filterStatusObj && this.filterStatusObj[0].Actions ? this.filterStatusObj[0].Actions.filter(x =>
        x.StatusId === this._data.StatusId &&
        x.SubStatusId === (this._data.SubStatusId ? this._data.SubStatusId : null))[0] || null
        : null;

      rolesListLastInteraction = this.getRolesList(this.filterStatusObj[0], this._data.StatusId, this._data.SubStatusId ? this._data.SubStatusId : null)
      if (this.counterValue > 0 && this.filterStatusObj && this.filterStatusObj[0].Actions && ((this.filterStatusObj[0].Actions.filter(x => x.StatusId == this._data.StatusId
        && x.SubStatusId == null)[0].Counter != 0) ||
        (this.filterStatusObj[0].Actions.filter(x => x.StatusId == this._data.StatusId
          && x.SubStatusId == this._data.SubStatusId)[0].Counter != 0))) {
        this.ShowLvmCounter = true;
      }
      else {
        this.ShowLvmCounter = false;
      }
      //if (this.screenType != CallListType.Recruiter && (this.screenType == CallListType.PreScreener && this.filterStatusObj[0].Roles.some(x => x.IsPrescreenScheduled === true)) || (this.screenType == CallListType.Screener && this.filterStatusObj[0].Roles.some(x => x.IsScreenScheduled === true)))
      if (this.screenType != CallListType.Recruiter && (this.filterStatusObj[0].Roles.some(x => x.IsPrescreenScheduled === true)) || (this.filterStatusObj[0].Roles.some(x => x.IsScreenScheduled === true))) {
        this.showConfirmation = true
      }
      else {
        this.showConfirmation = false
      }
      if (actionListLastInteraction && actionListLastInteraction.NextDate !== null) {
        this.showNextDate = true
      }
      else {
        this.showNextDate = false
      }
      if (actionListLastInteraction && actionListLastInteraction.ShowCalendar) {
        this.showCalendar = true
      }
      else {
        this.showCalendar = false
      }
      if (actionListLastInteraction && actionListLastInteraction.ShowSite) {
        this.showFields = true
      }
      else {
        this.showFields = false
      }
      if (this.screenType == CallListType.Screener && actionListLastInteraction && actionListLastInteraction.ShowScreenerFields) {
        this.showScreenerFields = true;
      }
      else {
        this.showScreenerFields = false;
      }
    }

    if (this.SubStatuses !== null && this.SubStatuses.length > 0) {
      this.showSubStatus = true;
    }
    else {
      this.showSubStatus = false;
    }
    if (this.filterStatusObj && rolesListLastInteraction && (rolesListLastInteraction.IsEnrolled || (!rolesListLastInteraction.IsEnrolled && rolesListLastInteraction.IsScreenConsented))) {
      this.showSubStatus = true;
      await this.createVisitlist();
      this.showUpdatedVisitList = true;
      this.showScreenerMandatoryFields = true
    }
    else {
      this.showUpdatedVisitList = false;
      this.showScreenerMandatoryFields = false
    }
    this.patientInteractionService.GetNextVisits('GetNextVisits', this._data.PatientId, this._data.StudyId)
    this.MarkInteractionForm.patchValue(this._data)
    this.MarkInteractionForm.controls.Id.setValue(this._data.Id)
    this.MarkInteractionForm.controls.StudyId.setValue(this._data.StudyId == 0 ? null : this._data.StudyId)
    this.MarkInteractionForm.controls.SiteId.setValue(this._data.SiteId == 0 ? '' : this._data.SiteId)

    // on dialog open check form valid with use of study id
    if (!!this.MarkInteractionForm.controls.StudyId.value) {
      this.checkValidFormBasedOnStudy(this.MarkInteractionForm.controls.StudyId.value);
    }

    this.MarkInteractionForm.controls.WindowEndDate.setValue(this.MarkInteractionForm.controls.WindowEndDate.value ? moment(this.MarkInteractionForm.controls.WindowEndDate.value).format(DATE_FORMAT) : null)
    this.MarkInteractionForm.controls.WindowStartDate.setValue(this.MarkInteractionForm.controls.WindowStartDate.value ? moment(this.MarkInteractionForm.controls.WindowStartDate.value).format(DATE_FORMAT) : null)
    this.MarkInteractionForm.controls.StudyVisitId.setValue(this._data.StudyVisitId == 0 ? null : this._data.StudyVisitId)
    this.MarkInteractionForm.controls.ScheduledDateTime2.setValue(this._data.ScheduledDateTime == null ? new Date().toISOString() : this._data.ScheduledDateTime)
    if (this._data.SiteCalendarId > 0) {
      this.MarkInteractionForm.controls.EventId.setValue(this._data.EventId)
    }
    else {
      this.MarkInteractionForm.controls.EventId.setValue("")
    }
    if (this._data.ScheduledDateTime && this._data.ScheduledDateTime != null) {
      this.dateTime = new Date(this._data.ScheduledDateTime).toLocaleString()
    }
    else {
      this.dateTime = new Date().toLocaleString()
    }
    await this.siteService.GetStudyWiseSites(this.MarkInteractionForm.controls.StudyId.value);
    this.LoadStudies();
    this.LoadSites();
    this.patientInteractionService.GetNextVisits('GetNextVisits', this._data.PatientId, this._data.StudyId)

    this.filterStatusObj = this.filteredData(this.AllStatusObj, this._data.StatusId)
    if (this._data.StatusId != 0 && this._data.StatusId != null && this.screenType == CallListType.Screener
      && this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsEnrolled == 1) {  //this._data.StatusId == 56
      this.MarkInteractionForm.controls['StudyId'].disable();
      this.statusService.GetRandomizedStatuses()
    }
    else if (this._data.StatusId != 0 && this._data.StatusId != null && this.screenType == CallListType.Screener
      && this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsEnrolled == 0
      && this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsScreenConsented == 1) { //this._data.StatusId == 44
      this.marKinteractionNextVisit = true
      this.MarkInteractionForm.controls['StudyId'].enable();
      this.statusService.GetStatusByType(this.screenType, true);
    }
    else {
      this.statusService.GetStatusByType(this.screenType, false);
    }
    if (this.screenType == CallListType.Screener
      && this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsEnrolled == 0
      && this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsScreenConsented == 1) { //his._data.StatusId == 44
      this.statusService.GetStatusByType(this.screenType, true);
    }
    // this.studyvisitService.GetStudyVisits();
    this.siteService.GetActiveSites()
    //this.showCalendar = false;
    //this.showFields = false
    //this.ChangeNextVisit()
    this.MarkInteractionForm.controls.SiteCalendarId.setValue(this._data.SiteCalendarId == 0 ? null : this._data.SiteCalendarId)
    // this.ChangeSite()
    if (this.data && this.data.disabledFields) {
      this.MarkInteractionForm.controls['Notes'].disable();
      this.MarkInteractionForm.controls['ScheduledDateTime2'].disable();
      this.MarkInteractionForm.controls['SiteCalendarId'].disable();
      this.MarkInteractionForm.controls['SiteId'].disable();
      this.MarkInteractionForm.controls['StatusId'].disable();
      this.MarkInteractionForm.controls['StudyId'].disable();
      this.MarkInteractionForm.controls['SubjectNumber'].disable();
      this.disabledFields = this.data.disabledFields
    }
    this.marKinteractionNextVisit = false
    if (actionListLastInteraction && actionListLastInteraction.ShowCalendar) {
      if (this._data.SiteCalendarId > 0) {
        this.showCalendar = true
        this.ChangeSiteCalendar();
      }
    }
    this.HideCreateButton();
    this.GetVisitProjectionByPatientVisit();
    this.EnableMarkIntScheduleVisitButton();

    if (this.data.isEdit) {
      this.edit = this.data.isEdit;
      this.showNotesMendatory = true
      this.MarkInteractionForm.controls['Notes'].setValidators([Validators.required, this.noWhitespaceValidator])
      this.DisabledAllFields()
    }
    else {
      this.edit = this.data.isEdit;
      // this.showNotesMendatory = false
      // this.MarkInteractionForm.controls['Notes'].setValidators(null)
      this.EnabledAllFields()
    }
    this.MarkInteractionForm.controls['Notes'].updateValueAndValidity();


    // if (this._data && this._data.Patient && this._data.Patient.StateID && this._data.Patient.StateID != 0) {
    this.patientInteractionService.GetCurrentorAfterTime()
    // }
    this.disableSiteAndSiteCalender(this.screenType, rolesListLastInteraction)
    if (!this.statusService._randomizedStatuses.find(x => x.Id === this.MarkInteractionForm.controls.StatusId.value)) {
      this.MarkInteractionForm.controls.StatusId.setValue(null);
    }
    
    this.statusIDs = this.statusService._randomizedStatuses.filter(x => {
      if (x.Name === 'PreScreen Scheduled' || x.Name === 'Screen Scheduled' || x.Name === 'Pre Booking') {
        return x.Id;
      }
    }).map(v => v.Id);

    // close dialog while click on exit->yes option
    this.scriptService.exitFromInteraction.subscribe(res => {
      if (!!res) {
        this.dialogRef.close();
      }
    });

    this.getQuestionValue.valueChanges.subscribe(v => {
      if (!!v) {
        this.questionList = [];
        this.questionList = v.questions;
        this.formDetails = {
          title: v.title,
          description: v.description
        }
        this.formId = v._id
        this.validity = v.validity;
      } else {
        this.questionList = [];
        this.formId = null;
        this.validity = null;
        this.formDetails = null;
      }
      setTimeout(() => {
        this.getResizedScreenHeight();
      }, 500);
    });
  }

  getPrescreenForm(): void {

    const formValue = this.MarkInteractionForm.getRawValue();
    if (!!formValue.StatusId && this.statusIDs.includes(formValue.StatusId) && formValue.StudyId) {

      this.spinner.show();
      this._Global.IsReady = false; 

      let params = new HttpParams()
        .append('Status', 'published')
        .append('Study', formValue.StudyId)
        .append('is_active', 'true')

      this.prescreenList.getPrescreenList(params).subscribe({
        next: (res) => {
          if (res.status) {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.prescreenFormData = res.data;
            if (this.prescreenFormData.length === 1) {
              this.formId = this.prescreenFormData[0].hasOwnProperty("_id")? this.prescreenFormData[0]._id : null;
              this.questionList = this.prescreenFormData[0].questions;
              this.validity = this.prescreenFormData[0].validity;
              this.formDetails = {
                title: this.prescreenFormData[0].title,
                description: this.prescreenFormData[0].description,
              }
            } else {
              // this.disabledButton = true
              this.getQuestionValue.setValue(null);
            }
          } else {
            this.spinner.hide();
            this._Global.IsReady = true;
          }
        },
        error: () => {
          this.spinner.hide();
          this._Global.IsReady = true;
        }
      });
    } else {
      this.getQuestionValue.setValue(null);
      this.isHideForm = true;
      this.formDetails = null;
    }
  }

  initForm() {
    
    this.MarkInteractionForm = this.fb.group({
      CrioId: [this._data.CrioId],
      Notes: [null, this.disabledFields ? '' : [Validators.required, this.noWhitespaceValidator]],
      PatientId: [this._data.PatientId],
      RecruiterId: [this._data.RecruiterId],
      ScheduledDateTime: [''],
      ScheduledDateTime2: [''],
      SiteCalendarId: [null],
      SiteId: [this._data.SiteId || '', this.screenType !== CallListType.Recruiter ? Validators.required : ''],
      StatusId: [null, Validators.required],
      StudyId: [this._data.StudyId || null, Validators.required],
      SlotId: [this.data.slotId || null],
      Id: [0],
      IsOutOfWindow: [''],
      IsUnscheduledVisit: [this._data.IsUnscheduledVisit],
      Questions: [],
      StudyVisitId: [this.showScreenerFields == true ? this._data.StudyVisitId : ''],
      SubStatusId: [null, this.subStatusRequired == true ? Validators.required : ''],
      SubjectNumber: [this._data.SubjectNumber],
      WindowEndDate: [this._data.WindowEndDate ? moment(new Date(this._data.WindowEndDate)).format('YYYY-MM-DD'):''],
      WindowStartDate: [this._data.WindowStartDate ? moment(new Date(this._data.WindowStartDate)).format('YYYY-MM-DD'):''],
      EventId: [''],
      Counter: [this._data.Counter],
      Confirmation: [this._data.Confirmation],
      NextDate: ['']
    })

    if (this.data.callList && this.data.callList.recruitmentSlotsConfig) {
      this.updateSlot = true;
      let details = this.data.callList.recruitmentSlotsConfig;
      this.onCalendarDateChange(details.date);
      this.updatedSlotId = details.id;
      this.onSlotClick(details);
    }
  }

  DisabledAllFields() {
    this.MarkInteractionForm.controls['CrioId'].disable();
    this.MarkInteractionForm.controls['SiteCalendarId'].disable();
    this.MarkInteractionForm.controls['SiteId'].disable();
    this.MarkInteractionForm.controls['StatusId'].disable();
    this.MarkInteractionForm.controls['StudyId'].disable();
    this.MarkInteractionForm.controls['StudyVisitId'].disable();
    this.MarkInteractionForm.controls['SubStatusId'].disable();
    this.MarkInteractionForm.controls['SubjectNumber'].disable();
    this.MarkInteractionForm.controls['WindowEndDate'].disable();
    this.MarkInteractionForm.controls['WindowStartDate'].disable();
    this.MarkInteractionForm.controls['Confirmation'].disable();
  }

  EnabledAllFields() {
    this.MarkInteractionForm.controls['CrioId'].enable();
    this.MarkInteractionForm.controls['SiteCalendarId'].enable();
    this.MarkInteractionForm.controls['SiteId'].enable();
    this.MarkInteractionForm.controls['StatusId'].enable();
    this.MarkInteractionForm.controls['StudyId'].enable();
    this.MarkInteractionForm.controls['StudyVisitId'].enable();
    this.MarkInteractionForm.controls['SubStatusId'].enable();
    this.MarkInteractionForm.controls['SubjectNumber'].enable();
    this.MarkInteractionForm.controls['WindowEndDate'].enable();
    this.MarkInteractionForm.controls['WindowStartDate'].enable();
    this.MarkInteractionForm.controls['Confirmation'].enable();
  }

  LoadSites() {
    this.siteService.GetSitesByPatientLocation(this.MarkInteractionForm.controls.PatientId.value).subscribe((res: any) => {
      let dataArray: Site[] = <Site[]>res;
      this.sitesonPatientLocation = dataArray;
      if (this._data.SiteId != null && this._data.SiteId != undefined) {
        this.sitesonPatientLocation = this.sitesonPatientLocation.filter(x => x.Id == this._data.SiteId)
      }
      else {
        this.sitesonPatientLocation = [];
      }
      this.getCombinedSitesData()
    },
      error => {
        this._Global.handleError(error);
        this._Global.IsReady = true;

      });
  }

  LoadStudies() {

    this.studyService.GetStudiesByPatientLocation(this.MarkInteractionForm.controls.PatientId.value, this.screenType).subscribe((res: any) => {
      let dataArray: Study[] = <Study[]>res;
      this.studiesonPatientLocation = dataArray.filter(o => o.IsActive == true)
      if (this._data.StudyId != 0 && this._data.StudyId != undefined && this._data.StudyId != null) {
        let filteredStudy: Study = dataArray.filter(o => o.Id == this._data.StudyId)[0]
        if (filteredStudy && this.studiesonPatientLocation && !this.studiesonPatientLocation.find(x => x.Id === filteredStudy.Id)) {
          this.studiesonPatientLocation.push(filteredStudy)
        }
      }
      else {
        this.studiesonPatientLocation = this.studiesonPatientLocation
      }
      this.getCombinedSitesData()
    },
      error => {
        this._Global.handleError(error);
        this._Global.IsReady = true;

      });
  }
  
  getSaveCallSuccess(event: {flag: boolean}): void {
    if (event.flag) {
      this.checkValidFormBasedOnStudy(this.MarkInteractionForm.controls.StudyId.value, true);
    }
  }

  checkValidFormBasedOnStudy(studyId: any, afterFormSave?: boolean): void {

    this.study = this.studiesonPatientLocation.find(data => data.Id == studyId);
    if (!afterFormSave) {
      this.getQuestionValue.setValue(null);
    }
    let params = new HttpParams().append('Study', studyId).append('Patient', (this._data.PatientId).toString());

    const validFormConst = this.prescreenService.getFormListBasedOnStudy(params).subscribe({
      next: (res) => {
        if (res.status) {
          if (isArray(res.data) ? !!res.data.length : !!res.data) {
            this.isHideForm = res.data.status === 'Qualified' ? true : false;
            
            if (new Date() > new Date(res.data.valid_to)) {
              this.showDNQMess = false;
              this.showQualifiedMess = false;
              this.DNQPatientMess = null;
              this.qualifiedPatientMess = null;
              this.isHideForm = false;
              this.getPrescreenForm();
            } else {
              if (!!afterFormSave) {
                if (res.data.status === 'Qualified') {
                  this.showQualifiedMess = true;
                  this.DNQPatientMess = null;
                  this.showDNQMess = false;
                  if (this.showQualifiedMess) {
                    this.qualifiedPatientMess = `Patient has successfully qualified for ${this.study.Name}. The phone screen is valid till ${this.datePipe.transform(res.data.valid_to, 'MM/dd/yyyy')}.`;
                  }
                } else {
                  this.showDNQMess = true;
                  this.qualifiedPatientMess = null;
                  this.showQualifiedMess = false;
                  if (this.showDNQMess) {
                    this.DNQPatientMess = `Patient has failed to qualify for ${this.study.Name} on ${this.datePipe.transform(res.data.valid_from, 'MM/dd/yyyy')}.`;
                  }
                }
              } else {
                this.showDNQMess = false;
                this.showQualifiedMess = false;
                this.DNQPatientMess = null;
                this.qualifiedPatientMess = null;
              }

              if (res.data.status === 'DNQ') {
                this.getPrescreenForm();
              }
              this.cd.detectChanges();
            }
          } else {
            this.showDNQMess = false;
            this.showQualifiedMess = false;
            this.DNQPatientMess = null;
            this.qualifiedPatientMess = null;
            this.isHideForm = false;
            this.getPrescreenForm();
          }
          this.disabledButton = false;
        }
      },
      error: (err) => {
        this.toastService.error('', err.message, this.toastOptions);
      }
    })

    this.subs.add(validFormConst);
  }

  async ChangeStudy(Event: any) {
    this.MarkInteractionForm.controls.StudyId.setValue(Event.value);
    this.checkValidFormBasedOnStudy(Event.value);
    this.slotListPageIndex = 0;
    this.onCalendarDateChange();

    if (this.MarkInteractionForm.controls.StudyId.value != null) {
      await this.siteService.GetStudyWiseSites(this.MarkInteractionForm.controls.StudyId.value);
    }
    else {
      this.MarkInteractionForm.controls.SiteId.setValue(null)
      this.siteService._studyWiseSites = []
    }
    if (this.MarkInteractionForm.controls.StatusId.value) {
      let statusObject = this.statusService._statusBytype.filter(o => o.Id == this.MarkInteractionForm.controls.StatusId.value)[0]
      const actionList = this.getActionList(statusObject, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null)

      if (actionList && actionList.Counter > 0) {
        const res = await this.patientInteractionService.GetInteractionCounter(this._data.PatientId, this.MarkInteractionForm.controls.StudyId.value, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null);
        if (res.item && res.item > 0) {
          this.counterValue = res.item
          this.ShowLvmCounter = true
        }
        else {
          this.ShowLvmCounter = false
        }
      }
      else {
        this.ShowLvmCounter = false
      }
    }
    this.getCombinedSitesData()
    setTimeout(() => {
      this.getResizedScreenHeight();
    }, 500);
  }

  getCombinedSitesData() {
    if (this.sitesonPatientLocation != undefined && this.siteService._studyWiseSites != undefined) {
      this.siteService._studyWiseSites = this.siteService._studyWiseSites.filter(element => element.Id !== (this.sitesonPatientLocation.length > 0 ? this.sitesonPatientLocation[0].Id : null));
      this.siteslist = [...this.siteService._studyWiseSites, ...this.sitesonPatientLocation]
    }
    else {
      this.siteslist = [];
    }
  }

  onNoClick(): void {
    this.prescreenService.isOpenInteraction.next(false);
    this.dialogRef.close();
    // (<any>window).ga('send', 'event', { // google event tracker//
    //   eventCategory: 'click',
    //   eventLabel: 'buttonClick',
    //   eventAction: 'onNoClick',
    // });
  }


  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }
  checkEdit(): SafeHtml {
    return this.edit ? this.sanitizer.bypassSecurityTrustHtml("<i class='icon-add'></i>") : this.sanitizer.bypassSecurityTrustHtml("<i class='ct-icon ct-icon-edit-mark'></i>");
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  async ChangeStatus() {

    this.checkValidFormBasedOnStudy(this.MarkInteractionForm.controls.StudyId.value);
    this.StudyQuestion = []
    this.clearFormFieldsOnChange(true)
    this.reserValidators();
    await this.setFormFieldsOnChange();
    let statusObject = this.statusService._statusBytype.filter(o => o.Id == this.MarkInteractionForm.controls.StatusId.value)[0]
    const actionList = this.getActionList(statusObject, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null )
    const rolesList = this.getRolesList(statusObject, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null )
    const lastInterationRoles = this.getRolesList(this.filterStatusObj[0],this._data.StatusId,this._data.SubStatusId ? this._data.SubStatusId : null )
    this.isSubStatusMandatory(rolesList, statusObject)
    this.showSubStatusDropDown(statusObject, actionList, rolesList)
    if(statusObject && actionList && (rolesList.IsEnrolled || (!rolesList.IsEnrolled && rolesList.IsScreenConsented))){
      this.showSubStatus = true;
      await this.createVisitlist();
      this.showUpdatedVisitList = true;
      this.showScreenerMandatoryFields = true;
      this.MarkInteractionForm.controls['SubjectNumber'].setValidators([Validators.required,])
      this.MarkInteractionForm.controls['StudyVisitId'].setValidators([Validators.required,])
      this.MarkInteractionForm.controls['SubStatusId'].setValidators([Validators.required,])
    }
    else {
      this.showUpdatedVisitList = false;
      this.showScreenerMandatoryFields = false;
      this.MarkInteractionForm.controls['SubjectNumber'].setValidators(null)
      this.MarkInteractionForm.controls['StudyVisitId'].setValidators(null)
      this.MarkInteractionForm.controls['SubStatusId'].setValidators(null)
    }

    //if (this.screenType != CallListType.Recruiter && (this.screenType == CallListType.PreScreener && rolesList.IsPrescreenScheduled) || (this.screenType == CallListType.Screener && rolesList.IsScreenScheduled ))
    if (statusObject && statusObject.Id == this.data.lastInterationStatusId && (rolesList.IsPrescreenScheduled || rolesList.IsScreenScheduled)) {
      this.showConfirmation = true
    }
    else {
      this.showConfirmation = false
      this.MarkInteractionForm.controls['Confirmation'].setValue(null);
      this.MarkInteractionForm.controls['Confirmation'].updateValueAndValidity();
    }

    this.MarkInteractionForm.controls['SubjectNumber'].updateValueAndValidity();
    this.MarkInteractionForm.controls['StudyVisitId'].updateValueAndValidity();
    this.MarkInteractionForm.controls['SubStatusId'].updateValueAndValidity();

    if (actionList && actionList.NextDate !== null) {
      this.showNextDate = true
      this.calculateDefaultNextDate(actionList.NextDate)
    }
    else {
      this.showNextDate = false
    }

    if (actionList && actionList.Counter > 0) {
      const res = await this.patientInteractionService.GetInteractionCounter(this._data.PatientId, this.MarkInteractionForm.controls.StudyId.value, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null);
      if (res.item && res.item > 0) {
        this.counterValue = res.item
        this.ShowLvmCounter = true
      }
      else {
        this.ShowLvmCounter = false
      }

    }
    else {
      this.ShowLvmCounter = false
    }

    if (actionList && actionList.ShowSite) {
      this.showFields = true
      //this.showCalendar = false
    }
    else {
      this.showFields = false
    }
    if (actionList && actionList.ShowCalendar) {
      this.showCalendar = true
    }
    else {
      this.showCalendar = false
    }
    if(this.showFields || this.showCalendar){
      this.updateValidationForSiteAndSiteCalender(this.showFields,this.showCalendar)
    }
    // if (this.showFields) {
    //   this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
    // }
    // else {
    //   this.MarkInteractionForm.controls['SiteId'].setValidators(null)
    // }
    // if(this.showCalendar){
    //   this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
    //   this.MarkInteractionForm.controls['SiteCalendarId'].setValidators([Validators.required,])
    // }
    // else {
    //   this.MarkInteractionForm.controls['SiteId'].setValidators(null)
    //   this.MarkInteractionForm.controls['SiteCalendarId'].setValidators(null)
    // }
    // this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
    // this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();


    if (this.screenType == CallListType.Screener) {

      if (statusObject.Actions && (statusObject.Actions.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
        && x.SubStatusId == this.MarkInteractionForm.controls.SubStatusId.value)[0].ShowScreenerFields)) {
        this.showScreenerFields = true
        this.showCalendar = true
        this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
        // this.MarkInteractionForm.controls['SiteCalendarId'].setValidators([Validators.required,])
      }
      else {
        this.MarkInteractionForm.controls['SiteId'].setValidators(null)
        // this.MarkInteractionForm.controls['SiteCalendarId'].setValidators(null)
        this.showScreenerFields = false
        this.showCalendar = false
      }
      this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
      this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();
      this.MarkInteractionForm.controls['Notes'].updateValueAndValidity();

      if (statusObject && statusObject.Actions && (statusObject.Actions.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
        && x.SubStatusId == this.MarkInteractionForm.controls.SubStatusId.value)[0].ShowCalendar)) {
        this.showCalendar = true
      }
      else {
        this.showCalendar = false
      }
      if(this.showFields || this.showCalendar){
        this.updateValidationForSiteAndSiteCalender(this.showFields,this.showCalendar)
      }
      // if (this.showFields) {
      //   this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
      // }
      // else {
      //   this.MarkInteractionForm.controls['SiteId'].setValidators(null)
      // }
      // if(this.showCalendar){
      //   this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
      //   this.MarkInteractionForm.controls['SiteCalendarId'].setValidators([Validators.required,])
      // }
      // else {
      //   this.MarkInteractionForm.controls['SiteId'].setValidators(null)
      //   this.MarkInteractionForm.controls['SiteCalendarId'].setValidators(null)
      // }
      // this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
      // this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();

      if (this._data && this._data.StatusId && this._data.StatusId != 0) {
        if (this.MarkInteractionForm.controls.StatusId.value != this._data.StatusId) {
          this.showNotesMendatory = true
          this.MarkInteractionForm.controls['Notes'].setValidators([Validators.required, this.noWhitespaceValidator])
        }
        else {
          this.showNotesMendatory = false
          this.MarkInteractionForm.controls['Notes'].setValidators(null)
        }
        this.MarkInteractionForm.controls['Notes'].updateValueAndValidity();
      }

      // if (this.MarkInteractionForm.controls.StatusId.value == 44 || this.MarkInteractionForm.controls.StatusId.value == 56) { //IsScreenCompleted ==1 with combination of status and substatus
      if (statusObject && statusObject.Roles && (statusObject.Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
        && x.SubStatusId == this.MarkInteractionForm.controls.SubStatusId.value)[0].IsScreenCompleted)) {
        this.showScreenerMandatoryFields = true
        this.MarkInteractionForm.controls['SubjectNumber'].setValidators([Validators.required,])
        this.MarkInteractionForm.controls['StudyVisitId'].setValidators([Validators.required,])
        this.MarkInteractionForm.controls['SubStatusId'].setValidators([Validators.required,])
      }
      else {
        this.showScreenerMandatoryFields = false
        this.MarkInteractionForm.controls['SubjectNumber'].setValidators(null)
        this.MarkInteractionForm.controls['StudyVisitId'].setValidators(null)
        this.MarkInteractionForm.controls['SubStatusId'].setValidators(null)
      }
      this.MarkInteractionForm.controls['SubjectNumber'].updateValueAndValidity();
      this.MarkInteractionForm.controls['StudyVisitId'].updateValueAndValidity();
      this.MarkInteractionForm.controls['SubStatusId'].updateValueAndValidity();
      //debugger
      if (!this.showScreenerFields) {
        //this.MarkInteractionForm.controls['SubjectNumber'].setValue(null);
        this.MarkInteractionForm.controls['StudyVisitId'].setValue(null);
        this.MarkInteractionForm.controls['SubStatusId'].setValue(null);
        this.MarkInteractionForm.controls['WindowStartDate'].setValue(null);
        this.MarkInteractionForm.controls['WindowEndDate'].setValue(null);
        this.HideCreateButton()
      }
    }
    else {
      this.showNotesMendatory = true
      this.MarkInteractionForm.controls['Notes'].setValidators([Validators.required, this.noWhitespaceValidator])
      this.MarkInteractionForm.controls['Notes'].updateValueAndValidity();
    }
    if(this.screenType && this.screenType == CallListType.PreScreener && rolesList  && (!rolesList.IsPrescreenScheduled || !lastInterationRoles.IsPrescreenScheduled)){
      this.enableSiteAndSiteCalender(actionList,lastInterationRoles,this.screenType)
    }
    else if(this.screenType && this.screenType == CallListType.Screener && rolesList  && !lastInterationRoles.IsEnrolled && (!rolesList.IsScreenScheduled || !lastInterationRoles.IsScreenScheduled)){
      this.enableSiteAndSiteCalender(actionList,lastInterationRoles,this.screenType)
    }
    else{
      this.disableSiteAndSiteCalender(this.screenType,rolesList)
    }
    setTimeout(() => {
      this.getResizedScreenHeight();
    }, 500);
  }

  async ChangeSubStatus() {

    this.StudyQuestion = []
    // if (this.MarkInteractionForm.controls.SubjectNumber.value) {
    //   this.MarkInteractionForm.controls.SubjectNumber.setValue(null)
    // }
    let statusObject = this.statusService._statusBytype.filter(o => o.Id == this.MarkInteractionForm.controls.StatusId.value)[0];
    const actionList = this.getActionList(statusObject, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId.value)
    const rolesList = this.getRolesList(statusObject, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId.value)

    if (actionList && actionList.NextDate !== null) {
      this.calculateDefaultNextDate(actionList.NextDate)
      this.showNextDate = true
    }
    else {
      this.showNextDate = false
    }

    if (actionList && actionList.Counter > 0) {
      const res = await this.patientInteractionService.GetInteractionCounter(this._data.PatientId, this.MarkInteractionForm.controls.StudyId.value, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null);
      if (res.item && res.item > 0) {
        this.counterValue = res.item
        this.ShowLvmCounter = true
      }
    }
    else {
      this.ShowLvmCounter = false
    }

    if (actionList && actionList.ShowSite == true) {
      this.showFields = true
      //this.showCalendar = false

    }
    else {
      this.showFields = false
    }
    if (actionList && actionList.ShowCalendar == true) {
      this.showCalendar = true
    }
    else {
      this.showCalendar = false
    }
    if(this.showFields || this.showCalendar){
      this.updateValidationForSiteAndSiteCalender(this.showFields,this.showCalendar)
    }
    // if (this.showFields) {
    //   this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
    // }
    // else {
    //   this.MarkInteractionForm.controls['SiteId'].setValidators(null)
    // }
    // if(this.showCalendar){
    //   this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
    //   this.MarkInteractionForm.controls['SiteCalendarId'].setValidators([Validators.required,])
    // }
    // else {
    //   this.MarkInteractionForm.controls['SiteId'].setValidators(null)
    //   this.MarkInteractionForm.controls['SiteCalendarId'].setValidators(null)
    // }

    if (rolesList && rolesList.IsComplianceStatus && rolesList.IsVisitStatusCondition == 4) {

      this.SkipVisit()
    }
    // this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
    // this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();

    if (this.screenType == CallListType.Screener) {
      if (actionList && actionList.ShowScreenerFields == true) {
        this.showScreenerFields = true
        this.showCalendar = true
        this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
        // this.MarkInteractionForm.controls['SiteCalendarId'].setValidators([Validators.required,])
        this.MarkInteractionForm.controls['SubjectNumber'].setValidators([Validators.required,])
        this.MarkInteractionForm.controls['StudyVisitId'].setValidators([Validators.required,])
      }
      else {
        this.MarkInteractionForm.controls['SiteId'].setValidators(null)
        // this.MarkInteractionForm.controls['SiteCalendarId'].setValidators(null)
        this.MarkInteractionForm.controls['SubjectNumber'].setValidators(null)
        this.MarkInteractionForm.controls['StudyVisitId'].setValidators(null)
        this.showScreenerFields = false
        this.showCalendar = false
      }
      this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
      this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();
      this.MarkInteractionForm.controls['Notes'].updateValueAndValidity();
      this.MarkInteractionForm.controls['SubjectNumber'].updateValueAndValidity();
      this.MarkInteractionForm.controls['StudyVisitId'].updateValueAndValidity();

      if (actionList && actionList.ShowCalendar) {
        this.showCalendar = true
      }
      else {
        this.showCalendar = false
      }
      if (this.showFields || this.showCalendar){
        this.updateValidationForSiteAndSiteCalender(this.showFields,this.showCalendar)
      }
      // if (this.showFields) {
      //   this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
      // }
      // else {
      //   this.MarkInteractionForm.controls['SiteId'].setValidators(null)
      // }
      // if(this.showCalendar){
      //   this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
      //   this.MarkInteractionForm.controls['SiteCalendarId'].setValidators([Validators.required,])
      // }
      // else {
      //   this.MarkInteractionForm.controls['SiteId'].setValidators(null)
      //   this.MarkInteractionForm.controls['SiteCalendarId'].setValidators(null)
      // }
      // this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
      // this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();

      if (this._data && this._data.StatusId && this._data.StatusId != 0) {
        if (this.MarkInteractionForm.controls.StatusId.value != this._data.StatusId) {
          this.showNotesMendatory = true
          this.MarkInteractionForm.controls['Notes'].setValidators([Validators.required,this.noWhitespaceValidator])
        }
        else {
          this.showNotesMendatory = false
          this.MarkInteractionForm.controls['Notes'].setValidators(null)
        }
        this.MarkInteractionForm.controls['Notes'].updateValueAndValidity();
      }
      //if (this.MarkInteractionForm.controls.StatusId.value == 44 || this.MarkInteractionForm.controls.StatusId.value == 56) {
      if (rolesList && rolesList.IsScreenCompleted) {
        this.showScreenerMandatoryFields = true
        this.MarkInteractionForm.controls['SubjectNumber'].setValidators([Validators.required,])
        this.MarkInteractionForm.controls['StudyVisitId'].setValidators([Validators.required,])
        this.MarkInteractionForm.controls['SubStatusId'].setValidators([Validators.required,])
      }
      else {
        this.showScreenerMandatoryFields = false
        this.MarkInteractionForm.controls['SubjectNumber'].setValidators(null)
        this.MarkInteractionForm.controls['StudyVisitId'].setValidators(null)
        this.MarkInteractionForm.controls['SubStatusId'].setValidators(null)
      }
      this.MarkInteractionForm.controls['SubjectNumber'].updateValueAndValidity();
      this.MarkInteractionForm.controls['StudyVisitId'].updateValueAndValidity();
      this.MarkInteractionForm.controls['SubStatusId'].updateValueAndValidity();

      if (!this.showScreenerFields) {
        this.MarkInteractionForm.controls['SubjectNumber'].setValue(null);
        this.MarkInteractionForm.controls['StudyVisitId'].setValue(null);
        this.MarkInteractionForm.controls['WindowStartDate'].setValue(null);
        this.MarkInteractionForm.controls['WindowEndDate'].setValue(null);
        this.HideCreateButton()
      }
    }
    else {
      this.showNotesMendatory = true
      this.MarkInteractionForm.controls['Notes'].setValidators([Validators.required,this.noWhitespaceValidator])
      this.MarkInteractionForm.controls['Notes'].updateValueAndValidity();
    }
  }

  EnableMarkIntScheduleVisitButton() {
    let statusObject = this.statusService._statusBytype.filter(o => o.Id == this.MarkInteractionForm.controls.StatusId.value)[0]
    const rolesList = this.getRolesList(statusObject, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId.value)

    if (this.screenType == CallListType.Screener) {
      // if ((this.MarkInteractionForm.controls.StatusId.value == 56 || this.MarkInteractionForm.controls.StatusId.value == 44
      //   || this.MarkInteractionForm.controls.StatusId.value == 18) && this.MarkInteractionForm.controls.SubStatusId.value == 55) {
      if (rolesList && rolesList.IsScreenConsented && rolesList.IsVisitStatusCondition == 2) {
        this.showMarkScheduleNextVisit = true
      }
      else {
        this.showMarkScheduleNextVisit = false
      }
    }
  }

  ChangeSite() {
    this.siteCalendarService.LoadSiteCalendarBySiteId(this.MarkInteractionForm.controls.SiteId.value);
    this.slotListPageIndex = 0;
    this.onCalendarDateChange();
    //this.viewDate = new Date();
  }

  ChangeSiteCalendar() {
    try {
      if (this.MarkInteractionForm.controls.SiteCalendarId.value != null) {
        if (this.showCalendar) {
          var { firstDay, lastDay } = this.getStartAndEndDateForCurrentMonth();
          let params = {
            EndDate: lastDay,
            SiteCalendarId: +this.MarkInteractionForm.controls.SiteCalendarId.value,
            StartDate: firstDay,
            params: {
              SiteCalendarId: +this.MarkInteractionForm.controls.SiteCalendarId.value,
              StartDate: firstDay,
              EndDate: lastDay
            }
          }
          this._Global.IsReady = false;
          caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
          const returnURL = document.location.href;
          const base = myGlobals._apiUrlBase;
          // window.location.replace(base+"Identity/Account/Login?returnUrl=" +returnURL);
          // window.location.replace("https://localhost:5001/Identity/Account/Login?returnUrl=" +returnURL);
          this.spinner.show()

          this.patientInteractionService.LoadAppointmentData(params).subscribe((res: any) => {
            if (res && res.length > 0) {
              // this.events = []
              res.forEach(element => {
                // this.events.push({
                //   title: element.Subject,
                //   start: new Date(element.StartTime),
                //   end: new Date(element.EndTime),
                //   allDay: element.Allday,
                //   color: colors.red,
                //   draggable: true,
                //   resizable: {
                //     beforeStart: true,
                //     afterEnd: true,
                //   },
                // })
              });
              this.refreshCalendar.next();

            }
            // this.events.push({
            //   title: '',
            //   start: null,
            //   end: null,
            //   color: colors.red,
            //   draggable: true,
            //   resizable: {
            //     beforeStart: true,
            //     afterEnd: true,
            //   },
            // })
            this._Global.IsReady = true
            this.spinner.hide()
          }, error => {
            this._Global.showErrorMsg(error.error && error.error.Value && error.error.Value.status ? error.error.Value.status : error);
            this._Global.IsReady = true;
            this.spinner.hide()
          })
        }
        else {
          return;
        }
      }

    } catch (error) {
      this._Global.handleError(error);
      this._Global.IsReady = true;
    }
  }

  lastindex() {
    // if (this.events && this.events.length > 0) {
    //   this.index = this.events.length - 1
    //   return this.index;
    // }
    // else {
    //   this.events.push({
    //     title: '',
    //     start: null,
    //     end: null,
    //     color: colors.red,
    //     draggable: true,
    //     resizable: {
    //       beforeStart: true,
    //       afterEnd: true,
    //     },
    //   })
    //   return this.index = 0
    // }
  }


  ChangeTitle(value) {
    this.lastindex()
    // this.events[this.index].title = value
  }

  get f() {
    return this.MarkInteractionForm.controls;
  }

  Save(getType: string) {
    if (this.disabledFields) {
      this.MarkInteractionForm.controls['Notes'].enable();
      this.MarkInteractionForm.controls['ScheduledDateTime2'].enable();
      this.MarkInteractionForm.controls['SiteCalendarId'].enable();
      this.MarkInteractionForm.controls['SiteId'].enable();
      this.MarkInteractionForm.controls['StatusId'].enable();
      this.MarkInteractionForm.controls['StudyId'].enable();
      this.MarkInteractionForm.controls['SubjectNumber'].enable();
    }
    if (this._data.StatusId != 0 && this._data.StatusId != null && this.screenType == CallListType.Screener
      && (this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsEnrolled)) { //this._data.StatusId == 56
      this.MarkInteractionForm.controls['StudyId'].enable();
    }
    if (this.showCalendar) {
      this.MarkInteractionForm.controls['ScheduledDateTime2'].enable();

      if(!(this.MarkInteractionForm.controls.ScheduledDateTime2.value instanceof Date)) {
        this.MarkInteractionForm.controls.ScheduledDateTime2.setValue(new Date(this.MarkInteractionForm.controls.ScheduledDateTime2.value));
      }
      
      this.dateTime = this.formatDateTime(new Date(this.MarkInteractionForm.controls.ScheduledDateTime2.value));
      this.MarkInteractionForm.controls.ScheduledDateTime.setValue(this.dateTime);
    }
    else {
      this.MarkInteractionForm.controls.ScheduledDateTime.setValue('')
      if (this.MarkInteractionForm.controls.ScheduledDateTime2.value) {
        this.MarkInteractionForm.controls.ScheduledDateTime2.setValue('')
      } 
      this.MarkInteractionForm.controls.EventId.setValue('')
    }
    if (this.showNextDate) {
      const convertNextDate = new Date(new Date(this.MarkInteractionForm.controls.NextDate.value).getTime() - new Date(this.MarkInteractionForm.controls.NextDate.value).getTimezoneOffset() * 60000).toISOString()
      this.MarkInteractionForm.controls.NextDate.setValue(convertNextDate)
    }
    else {
      if (this.MarkInteractionForm.controls.NextDate.value) {
        this.MarkInteractionForm.controls.NextDate.setValue(null)
      }
    }
    const siteIdControl = this.MarkInteractionForm.get('SiteId');
    const siteCalendarIdControl = this.MarkInteractionForm.get('SiteCalendarId');
    const studyIdControl = this.MarkInteractionForm.get('StudyId');
    var isTemporaryEnable = false;
    if (siteIdControl && siteIdControl.disabled) {
      this.MarkInteractionForm.get('SiteId').enable({ onlySelf: true });
      this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
      isTemporaryEnable = true
    }
    if (siteCalendarIdControl && siteCalendarIdControl.disabled) {
      this.MarkInteractionForm.get('SiteCalendarId').enable({ onlySelf: true });
      this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();
      isTemporaryEnable = true
    }
    if (studyIdControl && studyIdControl.disabled) {
      this.MarkInteractionForm.get('StudyId').enable({ onlySelf: true });
      this.MarkInteractionForm.controls['StudyId'].updateValueAndValidity();
      isTemporaryEnable = true
    }
    
    //this.disabledButton = true
    this._Global.IsReady = false
    this.spinner.show()
    this._ScreenerService.isCallApiDNQList.next(false)

    this.patientInteractionService.SaveMarkInteraction(this.MarkInteractionForm.value, getType, this.data.isEdit).subscribe(res => {
      if(isTemporaryEnable == true){
        this.MarkInteractionForm.get('SiteId').disable({ onlySelf: true });
        this.MarkInteractionForm.get('SiteCalendarId').disable({ onlySelf: true });
        this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
        this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();
        isTemporaryEnable = false;
      }
      this._Global.IsReady = true
      this.spinner.hide()
      this._Global.showSuccessMsg('Mark Interaction Saved successfully');
      this.disabledButton = false
      this._PatientDetailDialogService.closePatientDetailDialog();
      this._ScreenerService.isCallApiDNQList.next(true)
      if (this.screenType == CallListType.PreScreener && this.IsDNQ == false) {
        this._PrescreenerService.GetPreScreenerCallList(this.patientService.studyid, this.patientService.siteid,
          this.patientService.statusid, this.patientService.startDate, this.patientService.endDate, 0, 0, this.patientService.searchText, this.patientService.responseId);
      }
      else if (this.screenType == CallListType.Recruiter) {
        this._RecruiterService.GetRecruiterCallList(0, 0, -1, -1, -1, this.patientService.startDate, this.patientService.endDate, this.patientService.searchText)
      }
      else if (this.screenType == CallListType.Screener) {
        this._ScreenerService.GetScreenerCallList(false, this.patientService.studyid, this.patientService.siteid, this.patientService.statusid,
          this.patientService.studyvisit, this.patientService.startDate, this.patientService.endDate, 0, 0, this.patientService.searchText, this.patientService.responseId)
      }
      this.onNoClick()
    }, error => {
      this._ScreenerService.isCallApiDNQList.next(false)
      this.disabledButton = false
      this._Global.IsReady = true;
      this._Global.showErrorMsg(error.error && error.error.Value ? error.error.Value.status : error);
      this.spinner.hide()
    })
  }

  async MarkInteractionScheduleNextvisit() {
    this.submitted = true;
    this.disabledButton = true;
    this.MarkInteractionForm.controls['SubjectNumber'].setValidators([Validators.required,])
    this.MarkInteractionForm.controls['StudyVisitId'].setValidators([Validators.required,])
    this.MarkInteractionForm.controls['SubStatusId'].setValidators([Validators.required,])
    this.MarkInteractionForm.controls['Notes'].setValidators([Validators.required,])
    this.MarkInteractionForm.controls['SubjectNumber'].updateValueAndValidity();
    this.MarkInteractionForm.controls['StudyVisitId'].updateValueAndValidity();
    this.MarkInteractionForm.controls['SubStatusId'].updateValueAndValidity();
    this.MarkInteractionForm.controls['Notes'].updateValueAndValidity();
    if (this.showFields || this.showCalendar){
      this.updateValidationForSiteAndSiteCalender(this.showFields,this.showCalendar)
    }
    const res = await this.patientInteractionService.GetCompleteVisit(this._data.PatientId, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId.value, this.MarkInteractionForm.controls.StudyVisitId.value );
    if (this.MarkInteractionForm.invalid) {
      this.disabledButton = false;
      return;
    }
    else {
      if (res.isCompleteVisit == true){
        this.errorMessage = "This visit has already been marked completed before"
        this._Global.showErrorMsg(this.errorMessage)
        this.disabledButton = false;
        return;
      }
      if (confirm("Are you sure you want to use an older date?") == true) {
        this._Global.IsReady = false
        this.spinner.show()
        if (this.disabledFields) {
          this.MarkInteractionForm.controls['Notes'].enable();
          this.MarkInteractionForm.controls['ScheduledDateTime2'].enable();
          this.MarkInteractionForm.controls['SiteCalendarId'].enable();
          this.MarkInteractionForm.controls['SiteId'].enable();
          this.MarkInteractionForm.controls['StatusId'].enable();
          this.MarkInteractionForm.controls['StudyId'].enable();
          this.MarkInteractionForm.controls['SubjectNumber'].enable();
        }
        if (this._data.StatusId != 0 && this._data.StatusId != null && this.screenType == CallListType.Screener
          && (this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsEnrolled)) { //&& this._data.StatusId == 56
          this.MarkInteractionForm.controls['StudyId'].enable();
        }
        if (this.showCalendar) {      
          this.MarkInteractionForm.controls.ScheduledDateTime.setValue(this.formatDateTime(new Date()))
          this.MarkInteractionForm.controls.ScheduledDateTime2.setValue(this.formatDateTime(new Date()))
        }
        else {
          this.MarkInteractionForm.controls.ScheduledDateTime.setValue(null)
          if(this.MarkInteractionForm.controls.ScheduledDateTime2.value){
            this.MarkInteractionForm.controls.ScheduledDateTime2.setValue(null)
          }
          this.MarkInteractionForm.controls.EventId.setValue(null)
        }
        this.MarkInteractionForm.controls.WindowStartDate.setValue(moment(new Date()).format(DATE_FORMAT))
        this.MarkInteractionForm.controls.WindowEndDate.setValue(moment(new Date()).format(DATE_FORMAT))
        this.MarkInteractionForm.controls.Id.setValue(this._data.Id)
        const siteIdControl = this.MarkInteractionForm.get('SiteId');
        const siteCalendarIdControl = this.MarkInteractionForm.get('SiteCalendarId');
        var isTemporaryEnable = false;
        if (siteIdControl && siteIdControl.disabled) {
          this.MarkInteractionForm.get('SiteId').enable({ onlySelf: true });
          this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
          isTemporaryEnable = true
        }
        if (siteCalendarIdControl && siteCalendarIdControl.disabled) {
          this.MarkInteractionForm.get('SiteCalendarId').enable({ onlySelf: true });
          this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();
          isTemporaryEnable = true
        }
        if (this.data.isEdit) {
          this.EnabledAllFields()
        }
        this.patientInteractionService.SaveMarkInteraction(this.MarkInteractionForm.value, this.sceenApiType, this.data.isEdit).subscribe(async (res: any) => {
          if(isTemporaryEnable == true){
            this.MarkInteractionForm.get('SiteId').disable({ onlySelf: true });
            this.MarkInteractionForm.get('SiteCalendarId').disable({ onlySelf: true });
            this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
            this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();
            isTemporaryEnable = false;
          }
          this.marKinteractionNextVisit = true
          this.data.callList = null
          this._data = res
          console.log(res)
          this._Global.showSuccessMsg('Mark Interaction Saved successfully');
          this.disabledButton = false;
          this._Global.IsReady = true
          this.spinner.hide()
          await this.ngOnInit()
          this.MarkInteractionForm.controls.Notes.setValue('')
          this.submitted = false
          this.showMarkScheduleNextVisit = false
          this.MarkInteractionForm.controls.StudyVisitId.setValue(null)
          this.MarkInteractionForm.controls.SubStatusId.setValue(null)
          this.MarkInteractionForm.controls.SubjectNumber.setValue(null)
        }, error => {
          this.marKinteractionNextVisit = false
          this._Global.IsReady = true;
          this.disabledButton = false;
          this._Global.showErrorMsg(error.error && error.error.Value ? error.error.Value.status : error);
          this.spinner.hide()
        })
      }
      else {
        this.disabledButton = false;
      }
    }
  }

  addMonths(date, months) {
    date.setMonth((date.getMonth() + months) % 12);
    return date;
  }

  getBusinessHrs() {
    this.lovService.getLovDataById(LovStatusMasterID.BusinessHrs).subscribe(res => {
      this.lovData = <Lov[]>res;
    }, error => {
      this._Global.handleError(error);
    });
  }

  SchduleDateCondition(value: any) {
    var timeCondition: boolean = false

    let filterStatusObj = this.filteredData(this.AllStatusObj, this.MarkInteractionForm.controls.StatusId.value)

    let defaultAfterDate = moment(this.patientInteractionService._getCurrentorAfterTime.AfterDateTime).format(DATE_FORMAT);
    let currentDate = moment(this.patientInteractionService._getCurrentorAfterTime ? this.patientInteractionService._getCurrentorAfterTime.CurrentTime : new Date()).format('MM/DD/YYYY')
    let scheduleDate = moment(value).format('MM/DD/YYYY')
    let afterMonthsDate = moment(this.patientInteractionService._getCurrentorAfterTime ? this.patientInteractionService._getCurrentorAfterTime.AfterDateTime : this.addMonths(new Date(), 6)).format('MM/DD/YYYY')
    var startTime = 0
    var endTime = 0
    if (this.lovData && this.lovData.length > 0) {
      startTime = +this.lovData[1].Name
      endTime = +this.lovData[0].Name
    }
    else {
      startTime = 6
      endTime = 18
    }
    var scheduleTimeHrs: any = null
    var scheduleTimemins = moment(value).format('mm')
    var AmorPm = moment(value).format('a')
    if (AmorPm == 'am' && Number(moment(value).format('hh')) >= 12) {
      scheduleTimeHrs = moment(value).format('hh') + 12
    }
    else if (AmorPm == 'am') {
      scheduleTimeHrs = moment(value).format('hh')
    }
    else {
      if (Number(moment(value).format('hh')) != 12 && AmorPm == 'pm') {
        scheduleTimeHrs = Number(moment(value).format('hh')) + 12
      }
      else {
        scheduleTimeHrs = moment(value).format('hh')
      }
    }
    let time: String = scheduleTimeHrs + "." + scheduleTimemins
    var scheduleHours = (parseInt(time.split('.')[0]) * 60 + parseInt(time.split('.')[1])) / 60;
    if (scheduleHours >= startTime && scheduleHours <= endTime) {
      timeCondition = false
    }
    else {
      timeCondition = true
    }
    if (timeCondition) {
      this.errorMessage = "Schedule time out of business hours"
      this._Global.showErrorMsg("Schedule time out of business hours")
      this.disabledButton = false
      return true
    }
    else {
      this.errorMessage = ""
    }

    let _currentDate = new Date();

    let sixMonthsBeforeCurrentDate = new Date(_currentDate);
    sixMonthsBeforeCurrentDate.setMonth(sixMonthsBeforeCurrentDate.getMonth() - 6);


    if (new Date(scheduleDate) > new Date(afterMonthsDate)) {
      this.errorMessage = "Schedule date cannot be greater than " + defaultAfterDate
      this._Global.showErrorMsg("Schedule date cannot be greater than " + defaultAfterDate)
      this.disabledButton = false
      return true
    }
    // else if ((new Date(scheduleDate) < new Date(currentDate) && filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value && x.SubStatusId == null)[0].IsEnrolled == 0) || 
    // (filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value && x.SubStatusId == null)[0].IsEnrolled && new Date(scheduleDate) < sixMonthsBeforeCurrentDate)) {
    //   this.errorMessage = "Schedule date is in the past. Please select new date"
    //   this._Global.showErrorMsg("Schedule date is in the past. Please select new date")
    //   this.disabledButton = false
    //   return true
    // } // Task-5725 and Task-6225
    else {
      this.errorMessage = ""
    }
  }

  ChangeDate(value) {
    this.dateTime = new Date(value).toLocaleString()
    this.lastindex()
    this.viewDate = new Date(value)
    this.activeDayIsOpen = false
    // this.events[this.index].start = new Date(value)
    // this.events[this.index].end = new Date(value)
    this.SchduleDateCondition(value)
  }

  async SaveCalendarEvent(isCalenderSave: boolean) {
    this.submitted = true;
    this.disabledButton = true
    let filterStatusObj = this.filteredData(this.AllStatusObj, this.MarkInteractionForm.controls.StatusId.value)
    const actionList = this.getActionList(filterStatusObj[0], this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null)
    const rolesList = this.getRolesList(filterStatusObj[0], this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null)
    const visitCompleteResponse = await this.patientInteractionService.GetCompleteVisit(this._data.PatientId, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId.value, this.MarkInteractionForm.controls.StudyVisitId.value );
    this.isSubStatusMandatory(rolesList, filterStatusObj[0])

    this.updateFormControlsValidity(filterStatusObj, actionList, rolesList);
    
    if(this.showFields || this.showCalendar){
      this.updateValidationForSiteAndSiteCalender(this.showFields,this.showCalendar)
    }
    if (visitCompleteResponse.isCompleteVisit == true){
      this.errorMessage = "This visit has already been marked completed before"
      this._Global.showErrorMsg(this.errorMessage)
      this.disabledButton = false
      return;
    }
 
    if (this.MarkInteractionForm.valid) {
      try {
        if (actionList && actionList.Counter > 0) {
          const res = await this.patientInteractionService.GetInteractionCounter(this._data.PatientId, this.MarkInteractionForm.controls.StudyId.value, this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null);
          if(res.item != null && res.item > 0 && res.item >= actionList.Counter && !actionList.Dropoff){
            const errorMessage = "Counter has reached it's maximum limit."
            this.disabledButton = false
            return this._Global.showErrorMsg(errorMessage)
          }
          if (res.item != null && res.item > 0) {
            this.MarkInteractionForm.controls.Counter.setValue(res.item + 1);
          }
          else {
            this.MarkInteractionForm.controls.Counter.setValue(1);
          }
        }
        else {
          this.MarkInteractionForm.controls.Counter.setValue(0);
        }
                //change logic for save events

        if (this.showCalendar && isCalenderSave) {
          this.SchduleDateCondition(this.MarkInteractionForm.controls.ScheduledDateTime2.value)
        }
        else if (!isCalenderSave) {
          //--here we are getting date and time in two different variable,and requirment is concat them and set format before Save--//
          //-- date and time fomat strings will be move in constant file--//
          if (this.MarkInteractionForm.controls.Notes.invalid) {
            const errorMessage = "Notes must contain content, they cannot be blank."
            this.disabledButton = false
            return this._Global.showErrorMsg(errorMessage)
          }
          const date = moment(this.selectedDate, "dddd, D MMMM, YYYY");
          const time = moment(this.selectedTime, "HH:mm:ss");
          if (date.isValid() && time.isValid()) {
            const combinedDateTime = date.set({
              hour: time.hour(),
              minute: time.minute(),
              second: time.second(),
              millisecond: 0,
            });
            const formattedDateTime = combinedDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS");
            this.MarkInteractionForm.controls.ScheduledDateTime2.setValue(formattedDateTime);
          }
        }
        if (this.errorMessage != "") {
          this._Global.showErrorMsg(this.errorMessage)
        }
        else {
          if(this.showFields || this.showCalendar){
            this.updateValidationForSiteAndSiteCalender(this.showFields,this.showCalendar)
          }
          this.submitted = true;
          if (this.MarkInteractionForm.invalid) {
            this.disabledButton = false
            return;
          }
          else {
            if (this.data && this.data.disabledFields) {
              this.MarkInteractionForm.controls['Notes'].enable();
              this.MarkInteractionForm.controls['ScheduledDateTime2'].enable();
              this.MarkInteractionForm.controls['SiteCalendarId'].enable();
              this.MarkInteractionForm.controls['SiteId'].enable();
              this.MarkInteractionForm.controls['StatusId'].enable();
              this.MarkInteractionForm.controls['StudyId'].enable();
              this.MarkInteractionForm.controls['SubjectNumber'].enable();
              this.disabledFields = this.data.disabledFields
            }
            if (this._data.StatusId != 0 && this._data.StatusId != null && this.screenType == CallListType.Screener
              && (this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsEnrolled)) { //this._data.StatusId == 56
              this.MarkInteractionForm.controls['StudyId'].enable();
            }
            if (this.disabledStudyCrioId) {
              this.MarkInteractionForm.controls['StudyId'].enable();
              this.MarkInteractionForm.controls['CrioId'].enable();
              this.MarkInteractionForm.controls['SiteId'].enable();
              this.MarkInteractionForm.controls['SiteCalendarId'].enable();
            }
            if (this.data.isEdit) {
              this.EnabledAllFields()
            }
            if (this.screenType == CallListType.Screener && filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value && x.SubStatusId == null)[0].IsEnrolled == 1) { //&& this.MarkInteractionForm.controls.StatusId.value == 56
              if (this.MarkInteractionForm.controls.WindowStartDate.value != null && this.MarkInteractionForm.controls.WindowEndDate.value != null) {
                var WindowStartDate: any = moment(this.MarkInteractionForm.controls.WindowStartDate.value).format('MM/DD/YYYY')
                var WindowEndDate: any = moment(this.MarkInteractionForm.controls.WindowEndDate.value).format('MM/DD/YYYY')
                var ScheduleDate: any = moment(this.MarkInteractionForm.controls.ScheduledDateTime2.value).format('MM/DD/YYYY')
                var inRange = ScheduleDate >= WindowStartDate && ScheduleDate <= WindowEndDate;

                if (inRange) {
                  this.MarkInteractionForm.controls.IsOutOfWindow.setValue(false)
                  this.Save(this.sceenApiType)
                }
                else {
                  if (confirm("Schedule Date is out of window. Are you sure you want to save ?") == true) {
                    this.MarkInteractionForm.controls.IsOutOfWindow.setValue(true)
                    this.Save(this.sceenApiType)
                  }
                  else {
                    this.disabledButton = false
                    return;
                  }
                }
              }
              else {
                this.MarkInteractionForm.controls.IsOutOfWindow.setValue(false)
                this.Save(this.sceenApiType)
              }
            }
            else {
              this.MarkInteractionForm.controls.IsOutOfWindow.setValue(false)
              this.Save(this.sceenApiType)
            }
          }
        }
      }
      catch (error) {
        this._Global.handleError(error);
        this._Global.showErrorMsg(error);
        this._Global.IsReady = true;
        this.disabledButton = false
        this.spinner.hide()
      }
    }
    else{
      this.disabledButton = false
    }

  }

  updateFormControlsValidity(filterStatusObj, actionList, rolesList): void {
    if (this.subStatusRequired) {
      this.MarkInteractionForm.get('SubStatusId').setValidators([Validators.required]);
      this.MarkInteractionForm.get('SubStatusId').updateValueAndValidity();
    }
    if(filterStatusObj && actionList && (rolesList.IsEnrolled || (!rolesList.IsEnrolled && rolesList.IsScreenConsented))){
      this.showScreenerMandatoryFields = true;
      this.MarkInteractionForm.controls['SubjectNumber'].setValidators([Validators.required,])
      this.MarkInteractionForm.controls['StudyVisitId'].setValidators([Validators.required,])
      this.MarkInteractionForm.controls['SubStatusId'].setValidators([Validators.required,])
    }
    else {
      this.showUpdatedVisitList = false;
      this.showScreenerMandatoryFields = false;
      this.MarkInteractionForm.controls['SubjectNumber'].setValidators(null)
      this.MarkInteractionForm.controls['StudyVisitId'].setValidators(null)
      this.MarkInteractionForm.controls['SubStatusId'].setValidators(null)
    }
    this.MarkInteractionForm.controls['SubjectNumber'].updateValueAndValidity();
    this.MarkInteractionForm.controls['StudyVisitId'].updateValueAndValidity();
    this.MarkInteractionForm.controls['SubStatusId'].updateValueAndValidity();
  }

  HideCreateButton() {
    let filterStatusObj = this.filteredData(this.AllStatusObj, this.MarkInteractionForm.controls.StatusId.value)
    if (this.screenType == CallListType.Screener) {
      //if (this.MarkInteractionForm.controls.StudyVisitStatusId.value == 55) {
      if ((filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
          && (x.SubStausId == this.MarkInteractionForm.controls.SubStatusId.value || x.SubStatusId == null))[0].IsScreenConsented)
        && (filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
            && (x.SubStausId == this.MarkInteractionForm.controls.SubStatusId.value || x.SubStatusId == null))[0].IsVisitStatusCondition == 1)) {
        this.showCreateButton = false
      }
      else {
        this.showCreateButton = true
      }
    }
    else {
      this.showCreateButton = true
    }
  }

  ChangeNextVisit() {
    let filterStatusObj = this.filteredData(this.AllStatusObj, this.MarkInteractionForm.controls.StatusId.value)
      const actionList = this.getActionList(filterStatusObj[0], this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null)
      const rolesList = this.getRolesList(filterStatusObj[0], this.MarkInteractionForm.controls.StatusId.value, this.MarkInteractionForm.controls.SubStatusId ? this.MarkInteractionForm.controls.SubStatusId.value : null)
    
      this.updateFormControlsValidity(filterStatusObj, actionList, rolesList);
    
      if (this.screenType == CallListType.Screener) {
      // if ((this.MarkInteractionForm.controls.SubStatusId.value == 51 ||
      //   this.MarkInteractionForm.controls.SubStatusId.value == 54 ||
      //   this.MarkInteractionForm.controls.SubStatusId.value == 55) && this.patientInteractionService.nextVisitsArray &&
      //   this.patientInteractionService.nextVisitsArray.length > 0) {
      if (((filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
          && (x.SubStausId == this.MarkInteractionForm.controls.SubStatusId.value || x.SubStausId == null))[0].IsVisitStatusCondition == 1) ||
        (filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
            && (x.SubStausId == this.MarkInteractionForm.controls.SubStatusId.value || x.SubStausId == null))[0].IsVisitStatusCondition == 2)) &&
        this.patientInteractionService.nextVisitsArray &&
        this.patientInteractionService.nextVisitsArray.length > 0) {
        this.MarkInteractionForm.controls.StudyVisitId.setValidators([Validators.required,])
      }
      else {
        this.MarkInteractionForm.controls.StudyVisitId.setValidators(null)
      }
      this.MarkInteractionForm.controls.StudyVisitId.updateValueAndValidity()
      if (filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
          && (x.SubStausId == this.MarkInteractionForm.controls.SubStatusId.value || x.SubStausId == null))[0].IsVisitStatusCondition == 2) {
        this.spinner.show()
        this._Global.IsReady = false
        this.studyQuestionService.LoadData(this._data.StudyId, this._data.Id, this._data.StudyVisitId, true, false).subscribe(res => {
          this.StudyQuestion = <StudyQuestion[]>res as []
          // if ((this.MarkInteractionForm.controls.StatusId.value == 56 || this.MarkInteractionForm.controls.StatusId.value == 44
          //   || this.MarkInteractionForm.controls.StatusId.value == 18) && this.MarkInteractionForm.controls.SubStatusId.value == 55) {
          if (((filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
              && (x.SubStausId == this.MarkInteractionForm.controls.SubStatusId.value || x.SubStausId == null))[0].IsScreenScheduled) ||
            (filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
                && (x.SubStausId == this.MarkInteractionForm.controls.SubStatusId.value || x.SubStausId == null))[0].IsScreenCompleted))
            && (filterStatusObj[0].Roles.filter(x => x.StatusId == this.MarkInteractionForm.controls.StatusId.value
                && (x.SubStausId == this.MarkInteractionForm.controls.SubStatusId.value || x.SubStausId == null))[0].IsVisitStatusCondition == 2)
          ) {
            this.showMarkScheduleNextVisit = true
          }
          else {
            this.showMarkScheduleNextVisit = false
          }
          this.QuestionArray = []
          this.StudyQuestion.forEach(element => {
            this.QuestionArray.push({
              Answer: '',
              StudyQuestionId: ''
            })
          })
          this.spinner.hide()
          this._Global.IsReady = true
        }, error => {
          this._Global.showErrorMsg(error)
          this.spinner.hide()
          this._Global.IsReady = true
        })
      }
      else {
        this.showMarkScheduleNextVisit = false
        this.StudyQuestion = []
        return false;
      }
    }
  }

  ChangeYes(Id, value, index) {
    this.QuestionArray[index].StudyQuestionId = Id
    this.QuestionArray[index].Answer = value
  }

  ChangeNo(Id, value, index) {
    this.QuestionArray[index].StudyQuestionId = Id
    this.QuestionArray[index].Answer = value
  }

  ChangePending(Id, value, index) {
    this.QuestionArray[index].StudyQuestionId = Id
    this.QuestionArray[index].Answer = value
  }

  ChangeNA(Id, value, index) {
    this.QuestionArray[index].StudyQuestionId = Id
    this.QuestionArray[index].Answer = value
  }

  SkipVisit() {
    if (this._data.StatusId != 0 && this._data.StatusId != null && this.screenType == CallListType.Screener
      && (this.filterStatusObj[0].Roles.filter(x => x.StatusId == this._data.StatusId && x.SubStausId == null)[0].IsEnrolled)) { //&& this._data.StatusId == 56
      this.MarkInteractionForm.controls['StudyId'].enable();
    }
    else {
      this.MarkInteractionForm.controls['StudyId'].enable();
    }
    if (this.showCalendar) {
      this.MarkInteractionForm.controls.ScheduledDateTime.setValue(this.dateTime)
    }
    else {
      this.MarkInteractionForm.controls.ScheduledDateTime.setValue('')
    }
    if (this.MarkInteractionForm.controls.SiteCalendarId.value == null) {
      this._Global.showErrorMsg("Site Calendar is empty")
      return
    }
    if (this.MarkInteractionForm.controls.StudyVisitId.value == null) {
      this._Global.showErrorMsg("please select Next Visit")
      return
    }
    //this.MarkInteractionForm.controls.SubStatusId.setValue(58)//visit Skipped

    //this.spinner.show()
    //this._Global.IsReady = false
    this.QuestionArray = this.QuestionArray.filter(o => o.StudyQuestionId != '' && o.Answer != '')

    this.MarkInteractionForm.controls.Questions.setValue(this.QuestionArray)

    if (this.data.isEdit) {
      this.EnabledAllFields()
    }
  }

  onAddScheduleVisitToggle(flag){
    if(flag.checked === true)
    {
      this.GetUnscheduledVisits();
    }
    else{
      this.patientInteractionService.GetNextVisits('GetNextVisits', this._data.PatientId, this._data.StudyId);
      this.MarkInteractionForm.controls.IsUnscheduledVisit.setValue(false);
    }
  }

  GetUnscheduledVisits() {
    this.MarkInteractionForm.controls.IsUnscheduledVisit.setValue(true)
      this.spinner.show()
      this._Global.IsReady = false
      this.QuestionArray = this.QuestionArray.filter(o => o.StudyQuestionId != '' && o.Answer != '')
      this.MarkInteractionForm.controls.Questions.setValue(this.QuestionArray)
      this.patientInteractionService.GetUnscheduledVisits(this.MarkInteractionForm.controls.StudyId.value).subscribe((res: any) => {
        if (res && res.length && res.length > 0) {
          this.patientInteractionService.nextVisitsArray = []
          res.forEach(element => {
            this.patientInteractionService.nextVisitsArray.push({ Id: element.Id, Name: element.Name })
          });
        }
        this.patientInteractionService.isCallApiScreen.next(true)
        this.spinner.hide()
        this._Global.IsReady = true
      }, error => {
        this.patientInteractionService.isCallApiScreen.next(false)
        this.spinner.hide()
        this._Global.IsReady = true
      })
  }

  GetVisitProjectionByPatientVisit() {
    if (this.MarkInteractionForm.controls.StudyVisitId.value != null) {
      this.spinner.show()
      this._Global.IsReady = false
      this.patientInteractionService.GetVisitProjectionByPatientVisit(this.MarkInteractionForm.controls.PatientId.value,
        this.MarkInteractionForm.controls.StudyVisitId.value).subscribe((res: any) => {
          this.spinner.hide()
          this._Global.IsReady = true
          //this.MarkInteractionForm.controls.SubStatusId.setValue(null)
          if (res && res.Result && res.Result[0]) {
            this.MarkInteractionForm.controls.WindowStartDate.setValue(moment(res.Result[0].WindowStartDate).format('YYYY-MM-DD'))
            this.MarkInteractionForm.controls.WindowEndDate.setValue(moment(res.Result[0].WindowEndDate).format('YYYY-MM-DD'))
            this.MarkInteractionForm.controls.ScheduledDateTime.setValue(moment(res.Result[0].WindowStartDate).format('YYYY-MM-DD'))
            return
          }
          if (res && res.length > 0) {
            this.MarkInteractionForm.controls.WindowStartDate.setValue(moment(res[0].WindowStartDate).format('YYYY-MM-DD'))
            this.MarkInteractionForm.controls.WindowEndDate.setValue(moment(res[0].WindowEndDate).format('YYYY-MM-DD'))
            this.MarkInteractionForm.controls.ScheduledDateTime.setValue(moment(res[0].WindowStartDate).format('YYYY-MM-DD'))
            this.viewDate = moment(res[0].WindowStartDate).format('YYYY-MM-DD')
            return
          }
        }, error => {
          this._Global.showErrorMsg(error)
          this.spinner.hide()
          this._Global.IsReady = true
        })
    }
    else {
      this.MarkInteractionForm.controls.WindowStartDate.setValue(null)
      this.MarkInteractionForm.controls.WindowEndDate.setValue(null)
      this.statusService.LoadComplainceStatus()
      return;
    }
  }

  filteredData(AllStatusObj, StatusId) {
    const filteredArray = Object.keys(AllStatusObj)
      .filter(function (key) {
        return AllStatusObj[key].Id === StatusId;
      })
      .map(function (key) {
        return AllStatusObj[key];
      });

    return filteredArray;
  }

  calculateDefaultNextDate(counter) {
    var futureDateTime = new Date();
    futureDateTime.setDate(counter > 0 ? futureDateTime.getDate() + counter : futureDateTime.getDate());
    this.MarkInteractionForm.controls.NextDate.setValue(futureDateTime);
  }

  clearFormFieldsOnChange(isStatus: boolean) {

    if (isStatus) {
      this.MarkInteractionForm.controls.SiteId.setValue(null)
      this.MarkInteractionForm.controls.SiteCalendarId.setValue(null)
      this.MarkInteractionForm.controls.SubStatusId.setValue(null)
      if (this.MarkInteractionForm.controls.NextDate.value) {
        this.MarkInteractionForm.controls.NextDate.setValue(null)
      }
      // if (this.MarkInteractionForm.controls.SubjectNumber.value) {
      //   this.MarkInteractionForm.controls.SubjectNumber.setValue(null)
      // }
      if (this.MarkInteractionForm.controls.StudyVisitId.value) {
        this.MarkInteractionForm.controls.StudyVisitId.setValue(null)
      }
    }
    else {
      this.MarkInteractionForm.controls.SiteId.setValue(null)
      this.MarkInteractionForm.controls.SiteCalendarId.setValue(null)
      this.MarkInteractionForm.controls.ScheduledDateTime.setValue(null)
      if (this.MarkInteractionForm.controls.ScheduledDateTime2.value) {
        this.MarkInteractionForm.controls.ScheduledDateTime2.setValue(null)
      }
      if (this.MarkInteractionForm.controls.NextDate.value) {
        this.MarkInteractionForm.controls.NextDate.setValue(null)
      }
    }

  }
  async setFormFieldsOnChange() {
    if (this.lastInteractionStatusId === this.MarkInteractionForm.controls.StatusId.value) {
      try {
        const res = await this.patientInteractionService
          .GetLastPatientInteraction(this._data.PatientId)
          .toPromise();

          this.MarkInteractionForm.controls.SiteId.setValue(res.SiteId ? res.SiteId : null)
          this.MarkInteractionForm.controls.SiteCalendarId.setValue(res.SiteCalendarId ? res.SiteCalendarId : null)
          this.MarkInteractionForm.controls.SubStatusId.setValue(res.SubStatusId ? res.SubStatusId : null)
          this.MarkInteractionForm.controls.NextDate.setValue(res.NextDate ? res.NextDate : null)
          this.MarkInteractionForm.controls.StudyVisitId.setValue(res.StudyVisitId ? res.StudyVisitId : null)
          this.MarkInteractionForm.controls.ScheduledDateTime.setValue(res.ScheduledDateTime ? res.ScheduledDateTime : null )
          this.MarkInteractionForm.controls.ScheduledDateTime2.setValue(res.ScheduledDateTime ? res.ScheduledDateTime : null)
          this.MarkInteractionForm.controls.Confirmation.setValue(res.Confirmation ? res.Confirmation : null)
      } catch (error) {
        this._Global.showErrorMsg(error);
      }
    }
  }  

  getActionList(statusObject: any, statusId: number, subStatusId: any) {
    const actionList = statusObject && statusObject.Actions && statusObject.Actions.find(x =>
      x.StatusId === statusId &&
      x.SubStatusId === subStatusId) || null;
    return actionList
  }

   getRolesList(statusObject: any, statusId: number, subStatusId: any) {
    const rolesList = statusObject && statusObject.Roles && statusObject.Roles.filter(x =>
      x.StatusId === statusId &&
      (x.SubStatusId === subStatusId ));
    if(rolesList && rolesList.length > 0)
      return rolesList[0];
    else{
      return  statusObject && statusObject.Roles && statusObject.Roles.filter(x =>
        x.StatusId === statusId &&
        (x.SubStatusId === null ))[0];
    }
  }

  

  ChangeNextDate(event) {
    let value = event.target.value;
    this.dateTime = new Date(value).toLocaleString()
    this.lastindex()
    this.viewDate = new Date(value)
    this.activeDayIsOpen = false
    // this.events[this.index].start = new Date(value)
    // this.events[this.index].end = new Date(value)
    this.SchduleNextDateCondition(value)
  }

  SchduleNextDateCondition(value: any) {
    debugger
    var timeCondition: boolean = false
    let defaultAfterDate = moment(this.patientInteractionService._getCurrentorAfterTime.AfterDateTime).format(DATE_FORMAT);
    let currentDate = moment(this.patientInteractionService._getCurrentorAfterTime ? this.patientInteractionService._getCurrentorAfterTime.CurrentTime : new Date()).format('MM/DD/YYYY')
    let scheduleDate = moment(value).format('MM/DD/YYYY')
    let afterMonthsDate = moment(this.patientInteractionService._getCurrentorAfterTime ? this.patientInteractionService._getCurrentorAfterTime.AfterDateTime : this.addMonths(new Date(), 6)).format('MM/DD/YYYY')

    if (new Date(scheduleDate) > new Date(afterMonthsDate)) {
      this.errorMessage = "Schedule date cannot be greater than " + defaultAfterDate
      this._Global.showErrorMsg("Schedule date cannot be greater than " + defaultAfterDate)
      return true
    }
    else if (new Date(scheduleDate) < new Date(currentDate)) {
      this.errorMessage = "Schedule date is in the past. Please select new date"
      this._Global.showErrorMsg("Schedule date is in the past. Please select new date")
      return true
    }
    else {
      this.errorMessage = ""
    }
  }

  isSubStatusMandatory(rolesList: any, filterStatusObj: any) {
    if (rolesList && rolesList.IsRecruiterStatus == 1 && rolesList.IsPhoneScreenerStatus == 0 && rolesList.IsScreenerStatus == 0 && filterStatusObj.SubStatuses.length > 0) {
      this.subStatusRequired = true
    }
    else {
      this.subStatusRequired = false
    }
  }

  formatDateTime(scheduleDateTime: any) {
    return new Date(new Date(scheduleDateTime).getTime() - new Date(scheduleDateTime).getTimezoneOffset() * 60000).toISOString();
  }

  reserValidators() {
    this.MarkInteractionForm.get('SubStatusId').setErrors(null);
    this.MarkInteractionForm.get('SiteId').setErrors(null);
    // this.MarkInteractionForm.get('SiteCalendarId').setErrors(null);
  }

// Custom validator to check for white spaces
  noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && value.trim() === '') {
      return { 'whitespace': true };
    }
    return null;
  }

  onAddEvent() {
    this.showEventSchedule = true;
  }
  onEventClear() {
    this.showEventSchedule = false;
  }
  onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
    let query = new Query();
    let stitedata : any[]= this.studiesonPatientLocation;
    query = (e.text != "") ? query.where("Name", "Contains", e.text, true) : query;
    e.updateData(stitedata, query);
  };

  showSubStatusDropDown(statusObject, actionList, rolesList){

    if (this.screenType == CallListType.Recruiter) {
      this.subStatusForRecruiter(statusObject, actionList, rolesList)
    }
    if (this.screenType == CallListType.PreScreener) {
      this.subStatusForPrescreener(statusObject, actionList, rolesList)
    }
    if (this.screenType == CallListType.Screener) {
      this.subStatusForScreener(statusObject, actionList, rolesList)
    }
  }

  subStatusForRecruiter(statusObject, actionList, rolesList){
 
    if ((this.MarkInteractionForm.controls.StatusId.value == this.data.lastInterationStatusId && statusObject.SubStatuses.length > 0 && rolesList.IsPrescreenScheduled != true) ||
        (statusObject && statusObject.SubStatuses.length > 0 && actionList && actionList.Dropoff == false && rolesList.IsPrescreenScheduled != true && rolesList.IsScreenScheduled != true)) //statusRoles isprescreenSchedule == 0
      {
        this.SubStatuses = statusObject.SubStatuses.sort()
        this.showSubStatus = true
      }
      else {
        this.showSubStatus = false
      }
  }

  subStatusForPrescreener(statusObject, actionList, rolesList){
   
    // if ((this.MarkInteractionForm.controls.StatusId.value == this.data.lastInterationStatusId && statusObject.SubStatuses.length > 0 && rolesList.IsPrescreenScheduled != true) ||
    //     (statusObject && statusObject.SubStatuses.length > 0 && actionList && actionList.Dropoff == false && rolesList.IsScreenScheduled != true)) //statusRoles isScreenSchedule == 0
      if ((this.MarkInteractionForm.controls.StatusId.value == this.data.lastInterationStatusId && statusObject.SubStatuses.length > 0 && rolesList.IsPrescreenScheduled) ||
        (statusObject && statusObject.SubStatuses.length > 0 && rolesList.IsScreenScheduled != true && rolesList.IsPrescreenScheduled != true)) //statusRoles isScreenSchedule == 0
      {
        this.SubStatuses = statusObject.SubStatuses.sort()
        this.showSubStatus = true
      }
      else {
        this.showSubStatus = false
      }
  }
  subStatusForScreener(statusObject, actionList, rolesList){
    // if ((this.MarkInteractionForm.controls.StatusId.value == this.data.lastInterationStatusId && statusObject.SubStatuses.length > 0 && rolesList.IsPrescreenScheduled != true) ||
    //     (statusObject && statusObject.SubStatuses.length > 0 && actionList && actionList.Dropoff == false))
    if ((this.MarkInteractionForm.controls.StatusId.value == this.data.lastInterationStatusId && statusObject.SubStatuses.length > 0 && rolesList.IsScreenScheduled) ||
        (statusObject && statusObject.SubStatuses.length > 0 && rolesList.IsScreenScheduled != true && rolesList.IsPrescreenScheduled != true))
      {
        this.SubStatuses = statusObject.SubStatuses.sort()
        this.showSubStatus = true
      }
      else {
        this.showSubStatus = false
      }
  }

  async createVisitlist(){
    if(this.statusService._Compalinestatus != undefined && this.statusService._Compalinestatus != null){
      this.visitStatuslist = this.statusService._Compalinestatus[0].SubStatuses;
    }
    else {
      await this.statusService.GetComplainceStatus();
      this.visitStatuslist = this.statusService._Compalinestatus[0].SubStatuses;
    }
  }

  disableSiteAndSiteCalender(screentype,rolesListLastInteraction){
    if(screentype && screentype == CallListType.PreScreener && rolesListLastInteraction  && rolesListLastInteraction.IsPrescreenScheduled){
      if(!this.edit)
      {
        this.MarkInteractionForm.controls['ScheduledDateTime2'].disable();
      }
      else {
        this.MarkInteractionForm.controls['NextDate'].disable();
      }
      this.MarkInteractionForm.controls['SiteCalendarId'].disable();
      this.MarkInteractionForm.controls['SiteId'].disable();
      this.MarkInteractionForm.controls['StudyId'].disable();
    }
    if(screentype && screentype == CallListType.Screener && rolesListLastInteraction && rolesListLastInteraction.IsScreenScheduled ){
      if(!this.edit)
      {
        this.MarkInteractionForm.controls['ScheduledDateTime2'].disable();
      }
      else {
        this.MarkInteractionForm.controls['NextDate'].disable();
      }
      this.MarkInteractionForm.controls['SiteCalendarId'].disable();
      this.MarkInteractionForm.controls['SiteId'].disable();
      this.MarkInteractionForm.controls['StudyId'].disable();
    }
    if(screentype && screentype == CallListType.Screener && rolesListLastInteraction && rolesListLastInteraction.IsEnrolled){
      this.MarkInteractionForm.controls['SiteCalendarId'].disable();
      this.MarkInteractionForm.controls['SiteId'].disable();
      this.MarkInteractionForm.controls['StudyId'].disable();
    }
  }

  onCalendarDateChange(event?: any): void {
    this.startDate = event ? typeof event === 'string' && event.includes('T') ? new Date(event.split('T')[0]) : event : new Date();
    const today = this.startDate;
    const dayOfWeek = today.getDay();
    const firstDayOfWeek = today.getDate() - dayOfWeek;
    let minDate = new Date(today.setDate(firstDayOfWeek));
    let maxDate = new Date(today.setDate(this.startDate.getDate() + 6));
    this.calStartDate = this.datePipe.transform(minDate, 'yyyy-MM-dd');
    this.calEndDate = this.datePipe.transform(maxDate, 'yyyy-MM-dd');
    this.getAvailableSlotList();
  }
 
  onSlotClick(data: any): void {
    this.events = [];
    this.MarkInteractionForm.controls['SlotId'].setValue(data.id);
    this.slotDate = data.date.includes('T') ?
                    this.datePipe.transform(data.date.split('T')[0], 'MM-dd-yyyy') : this.datePipe.transform(data.date, 'MM-dd-yyyy');
    this.slotTime = data.startTime + ' - ' + data.endTime;
    const start = new Date(`${this.slotDate} ${data.startTime}`);
    const end = new Date(`${this.slotDate} ${data.endTime}`);
    let css = data.bookingPercentage === 100 ? 'my-custom-class full-slot' :
              data.bookingPercentage < 50 ? 'my-custom-class active-slot' :
              data.bookingPercentage >= 50 ? 'my-custom-class medium-slot' : '';
    this.events.push({
      start,
      end,
      title: data.totalRemainingSeats || 0,
      meta: {
        studyName: data.studyName,
      },
      cssClass: css
    });
    this.refresh.next();
  }

  updatePatientSlot(): void {
    if (this.MarkInteractionForm.valid) {
      this.submitted = true;
      this.disabledButton = true;
      if (this.updatedSlotId === this.MarkInteractionForm.controls['SlotId'].value) {
        this.toastService.error('', 'Please select other slot for reschedule the patient', this.toastOptions);
        this.submitted = false;
        this.disabledButton = false;
      } else {
        this.spinner.show();
        this._Global.IsReady = false;
  
        let params = {
          slotId: this.MarkInteractionForm.controls['SlotId'].value,
          id: this.rowData['patientLastInteraction.PatientInteractionId']
        }
        this.recruiterService.reschedulePatientSlot(params).subscribe({
          next: (res) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            if (res.status) {
              this.dialogRef.close(true);
              this.toastService.success('', res.message, this.toastOptions);
            } else {
              this.toastService.error('', res.message, this.toastOptions);
              this.submitted = false;
              this.disabledButton = false;
            }
          },
          error: (err) => {
            this.spinner.hide();
            this._Global.IsReady = true;
            this.submitted = false;
            this.disabledButton = false;
            this.toastService.error('', err.message, this.toastOptions);
          }
        });
      }
    } else {
      this.submitted = true;
    }
  }
      
  getAvailableSlotList(isLoadMore?: boolean): void {
    if (this.MarkInteractionForm.controls['SiteId'].value && String(this.MarkInteractionForm.controls.StudyId.value)) {
      if (!this.moreSlotsLoader) {
        this.spinner.show();
        this._Global.IsReady = false;
      }

      let params = {
        siteId: this.MarkInteractionForm.controls['SiteId'].value,
        studies: String(this.MarkInteractionForm.controls.StudyId.value),
        startDate: this.calStartDate,
        endDate: this.calEndDate,
        page: this.slotListPageIndex = isLoadMore ? this.slotListPageIndex + 1 : 1,
        pageSize: 10,
      }

      this.recruiterService.getAvailableSlots(params).subscribe({
        next: (res) => {
          if (res.status) {
            if (isLoadMore) {
              this.availableSlotData = this.availableSlotData.length ? 
                [...this.availableSlotData, ...res.data] : res.data;
            } else {
              this.availableSlotData = res.data;
            }
            this.isMoreData = res.isMore;
          } else {
            this.toastService.error('', res.message, this.toastOptions);
          }
          if (this.moreSlotsLoader) {
            this.moreSlotsLoader = false;
          } else {
            this.spinner.hide();
            this._Global.IsReady = true;
          }
        },
        error: (err) => {
          this.spinner.hide();
          this._Global.IsReady = true;
          this.toastService.error('', err.message, this.toastOptions);
        }
      });
    }
  }

  onScroll(event: any): void {
    if (!this.moreSlotsLoader && this.isMoreData) {
      const element = event.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.moreSlotsLoader = true;
        this.getAvailableSlotList(true);
      }
    }
  }

  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MM-dd-yyyy');
  }

  enableSiteAndSiteCalender(actionList,rolesListLastInteraction,screentype){
    
    if(actionList.ShowCalendar){
      this.MarkInteractionForm.controls['ScheduledDateTime2'].enable();
      this.MarkInteractionForm.controls['SiteCalendarId'].enable();
      this.MarkInteractionForm.controls['SiteId'].enable();
    }
    if(actionList.ShowSite){
      this.MarkInteractionForm.controls['SiteId'].enable();
    }
    if(this.showNextDate){
      this.MarkInteractionForm.controls['NextDate'].enable();
    }
    if((screentype == CallListType.PreScreener && !rolesListLastInteraction.IsPrescreenScheduled) || 
        (screentype == CallListType.Screener &&!rolesListLastInteraction.IsScreenScheduled)){
      this.MarkInteractionForm.controls['StudyId'].enable();
    }  
  }

  getStartAndEndDateForCurrentMonth(){
    //const currentDate = new Date();
    
    let currentMonth = this.viewDate.getMonth() + 1;
    let currentYear = this.viewDate.getFullYear();
    let lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
    let firstDay  = new Date(currentYear, currentMonth - 1, 1, 0, 0, 0);
    let lastDay = new Date(currentYear, currentMonth - 1, lastDayOfMonth, 23, 59, 59);

    return {firstDay,lastDay};
  }
  updateValidationForSiteAndSiteCalender(showFields: boolean,showCalendar: boolean){
    if(showFields && showCalendar){
      this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
      // this.MarkInteractionForm.controls['SiteCalendarId'].setValidators([Validators.required,])
    }
    if(!showFields && showCalendar){
      this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
      // this.MarkInteractionForm.controls['SiteCalendarId'].setValidators([Validators.required,])
    }
    if(showFields && !showCalendar){
      this.MarkInteractionForm.controls['SiteId'].setValidators([Validators.required,])
      // this.MarkInteractionForm.controls['SiteCalendarId'].setValidators(null)
    }
    if(!showFields && !showCalendar){
      this.MarkInteractionForm.controls['SiteId'].setValidators(null)
      // this.MarkInteractionForm.controls['SiteCalendarId'].setValidators(null)
    }
    this.MarkInteractionForm.controls['SiteId'].updateValueAndValidity();
    // this.MarkInteractionForm.controls['SiteCalendarId'].updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}